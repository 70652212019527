import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';
import { getComparator, stableSort } from '../Utils/SortArray';

export default function Glossary() {
  const [glossaryItems, setGlossaryItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('term');
  const { token } = useToken();

  const fetchGlossary = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await fetchWithAuth(
        'glossary',
        null,
        token,
        'GET'
      );
      
      if (Array.isArray(response)) {
        setGlossaryItems(response);
        setFilteredItems(response);
      } else {
        setError('Invalid response format');
      }
    } catch (err) {
      setError('Failed to fetch glossary items');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGlossary();
  }, []);

  useEffect(() => {
    // Filter glossary items based on search term
    if (searchTerm.trim() === '') {
      setFilteredItems(glossaryItems);
    } else {
      const term = searchTerm.toLowerCase();
      const filtered = glossaryItems.filter(item => 
        (item.term && item.term.toLowerCase().includes(term)) || 
        (item.definition && item.definition.toLowerCase().includes(term))
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, glossaryItems]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort the filtered items
  const sortedItems = stableSort(filteredItems, getComparator(order, orderBy));
  
  // For debugging
  useEffect(() => {
    if (glossaryItems.length > 0) {
      console.log('Glossary data loaded:', glossaryItems.length, 'items');
      console.log('Sample item:', glossaryItems[0]);
    }
  }, [glossaryItems]);

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Glossary
          </CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <div className="text-red-600 text-sm mb-4">{error}</div>
          )}

          <div className="mb-6">
            <p className="text-gray-600">
              Use the glossary to find definitions for racing terminology and Smart Ratings concepts.
            </p>
          </div>

          <div className="mb-4">
            <input
              type="text"
              placeholder="Search glossary..."
              className="w-full p-2 border border-gray-300 rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {loading && <div className="text-center py-4">Loading...</div>}

          {!loading && filteredItems.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              {searchTerm 
                ? "No glossary items match your search" 
                : "No glossary items found"}
            </div>
          )}

          {!loading && filteredItems.length > 0 && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('term')}
                    >
                      Term {orderBy === 'term' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('definition')}
                    >
                      Definition {orderBy === 'definition' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {sortedItems.map((item) => (
                    <tr key={item._id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{item.term}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500" style={{ maxWidth: '800px' }}>
                          {item.definition}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-6">
            <button
              onClick={fetchGlossary}
              disabled={loading}
              className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors disabled:bg-gray-100 disabled:text-gray-400"
            >
              Refresh
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}