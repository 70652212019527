import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-4 text-center">
      <p className="text-gray-600">
        Powered by <a href="https://www.jhjwconsulting.com/" target="_blank" rel="noopener noreferrer" className="text-indigo-500">JHJW Consulting Ltd.</a>
      </p>
    </footer>
  );
};

export default Footer;
