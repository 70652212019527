import React from 'react';
import { useTheme } from '../Theme/ThemeProvider';

export const Card = ({ className = '', ...props }) => {
  const { theme } = useTheme();
  const isBlandford = theme === 'blandford';
  
  return (
    <div 
      className={`rounded-lg border shadow-sm themed-card ${
        isBlandford 
          ? 'bg-[#fffdf0] text-[#2f2355] border-[#9790a2]' 
          : 'bg-white text-gray-900'
      } ${className}`} 
      {...props} 
    />
  );
};

export const CardHeader = ({ className = '', ...props }) => {
  const { theme } = useTheme();
  const isBlandford = theme === 'blandford';
  
  return (
    <div 
      className={`flex flex-col space-y-1.5 p-6 ${
        isBlandford ? 'border-b border-[#9790a2]' : ''
      } ${className}`} 
      {...props} 
    />
  );
};

export const CardTitle = ({ className = '', ...props }) => {
  const { theme } = useTheme();
  const isBlandford = theme === 'blandford';
  
  return (
    <h3 
      className={`text-2xl font-semibold leading-none tracking-tight ${
        isBlandford ? 'text-[#2f2355]' : ''
      } ${className}`} 
      {...props} 
    />
  );
};

export const CardDescription = ({ className = '', ...props }) => {
  const { theme } = useTheme();
  const isBlandford = theme === 'blandford';
  
  return (
    <p 
      className={`text-sm ${
        isBlandford ? 'text-[#2f2355]/80' : 'text-gray-500'
      } ${className}`} 
      {...props} 
    />
  );
};

export const CardContent = ({ className = '', ...props }) => (
  <div className={`p-6 pt-0 ${className}`} {...props} />
);

export const CardFooter = ({ className = '', ...props }) => {
  const { theme } = useTheme();
  const isBlandford = theme === 'blandford';
  
  return (
    <div 
      className={`flex items-center p-6 pt-0 ${
        isBlandford ? 'border-t border-[#9790a2] mt-4 pt-4' : ''
      } ${className}`} 
      {...props} 
    />
  );
};

export default {
  Card,
  CardHeader,
  CardTitle, 
  CardDescription,
  CardContent,
  CardFooter
};