import React, { useState, useEffect, useRef, useMemo } from 'react';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';
import ExceptionDataTable from './components/ExceptionDataTable';
import logo from '../../images/SR.svg';
import Footer from '../Footer/Footer';

function ExceptionDashboard() {
  const [exceptionType, setExceptionType] = useState('');
  const [countryCode, setCountryCode] = useState('');

  // State variables for Unrated Races dates
  const [unratedFromDate, setUnratedFromDate] = useState('');
  const [unratedToDate, setUnratedToDate] = useState(new Date().toISOString().split('T')[0]);

  // State variables for History Vs Live dates
  const [historyFromDate, setHistoryFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().split('T')[0]
  );
  const [historyToDate, setHistoryToDate] = useState('');

  const [data, setData] = useState(null);
  const lastFetchId = useRef(0);
  const { token } = useToken();

  let displayFromDate = '';
  let displayToDate = '';

  if (exceptionType === 'Unrated Races') {
    displayFromDate = unratedFromDate;
    displayToDate = unratedToDate;
  } else if (exceptionType === 'History Vs Live') {
    displayFromDate = historyFromDate;
    displayToDate = historyToDate;
  }

  const [selectedExceptionType, setSelectedExceptionType] = useState('');
  const handleExceptionTypeFilterChange = (e) => setSelectedExceptionType(e.target.value);

  const handleExceptionTypeChange = (e) => {
    setExceptionType(e.target.value);
    setCountryCode('');
    setData(null);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
    setData(null);
  };

  const exceptionTypes = [
    "WinTimeDifference",
    "SmartRatingDifference",
    "HorseWgtCarriedDifference",
    "EffectWgtCarriedDifference",
    "BeatenDistanceDifference",
    "FinishingPositionDifference",
    "SmartFormDifference",
    "SmartTimeLiveDifference",
    "SmartTimeFullDifference",
    "SmartSecTimeDifference",
    "All",
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!countryCode || !exceptionType) return;
  
      const fetchId = ++lastFetchId.current;
      let endpoint = '';
      let params = {};
  
      switch (exceptionType) {
        case 'Unrated Races':
          endpoint = 'unratedRacesExceptions';
          const countryCodesToFetch =
            countryCode === 'All' ? ['GB_F', 'GB_J', 'IRE_F', 'IRE_J', 'FR_F'] : [countryCode];
  
          try {
            const fetchPromises = countryCodesToFetch.map((code) =>
              fetchWithAuth(
                endpoint,
                {
                  countryCode: code,
                  fromDate: unratedFromDate || undefined,
                  toDate: unratedToDate || undefined,
                },
                token
              )
            );
            const results = await Promise.all(fetchPromises);
            const combinedData = results.flat();
  
            if (fetchId === lastFetchId.current) {
              setData(combinedData);
            }
          } catch (error) {
            if (fetchId === lastFetchId.current) {
              console.error('Error fetching data:', error);
              setData(null);
            }
          }
          break;
  
        case 'General Exceptions':
          endpoint = 'generalExceptions';
          params = {
            countryCode,
            fromDate: unratedFromDate || undefined,
            toDate: unratedToDate || undefined,
          };
          
          try {
            const fetchedData = await fetchWithAuth(endpoint, params, token);
            if (fetchId === lastFetchId.current) {
              setData(fetchedData);
            }
          } catch (error) {
            if (fetchId === lastFetchId.current) {
              console.error('Error fetching data:', error);
              setData(null);
            }
          }
          break;
        
        case 'General Exception Summaries':
          endpoint = 'generalExceptionSummaries';
          params = { countryCode };
        
          try {
            const fetchedData = await fetchWithAuth(endpoint, params, token);
            console.log('Fetched Data:', fetchedData); 
            if (fetchId === lastFetchId.current) {
              setData(fetchedData);
            }
          } catch (error) {
            if (fetchId === lastFetchId.current) {
              console.error('Error fetching general exception summaries:', error);
              setData(null);
            }
          }
          break;

        case 'History Vs Live':
          endpoint = 'historyLiveExceptions';
          params = {
            countryCode,
            fromDate: historyFromDate || undefined,
            toDate: historyToDate || undefined,
            exceptionType: selectedExceptionType || undefined,
          };
  
          try {
            const fetchedData = await fetchWithAuth(endpoint, params, token);
            if (fetchId === lastFetchId.current) {
              setData(fetchedData);
            }
          } catch (error) {
            if (fetchId === lastFetchId.current) {
              console.error('Error fetching data:', error);
              setData(null);
            }
          }
          break;
  
        default:
          return;
      }
    };
  
    fetchData();
  }, [
    exceptionType,
    countryCode,
    unratedFromDate,
    unratedToDate,
    historyFromDate,
    historyToDate,
    selectedExceptionType,
    token,
  ]);

  useEffect(() => {
    if (exceptionType === 'History Vs Live') {
      setHistoryFromDate(
        new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().split('T')[0]
      );
      setHistoryToDate('');
    } else if (exceptionType === 'Unrated Races') {
      setUnratedFromDate('');
      setUnratedToDate(new Date().toISOString().split('T')[0]);
    }
  }, [exceptionType]);
  

  // -------------------------------------------------------------------------

  // Summary stats for Unrated Races
  const unratedSummary = useMemo(() => {
    if (exceptionType !== 'Unrated Races' || !data || data.length === 0) return null;

    const totalRows = data.length;
    const smartRatedCount = data.filter(row => row.SmartRated === 1).length;
    const smartTimeExistsCount = data.filter(row => row.WinSmartTimeExist === 1).length;

    return {
      totalRows,
      smartRatedPercent: ((smartRatedCount / totalRows) * 100).toFixed(2),
      smartTimeExistsPercent: ((smartTimeExistsCount / totalRows) * 100).toFixed(2),
    };
  }, [exceptionType, data]);

  // Summary stats for History Vs Live
  const historyVsLiveSummary = useMemo(() => {
    if (exceptionType !== 'History Vs Live' || !data || data.length === 0) {
      return null;
    }

    const totalRows = data.length;
    const values = data.map(row => row.Difference).filter(value => value !== undefined);

    const median = values.sort((a, b) => a - b)[Math.floor(values.length / 2)];
    const min = Math.min(...values);
    const max = Math.max(...values);
    const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
    const stdev = Math.sqrt(values.reduce((sq, value) => sq + Math.pow(value - mean, 2), 0) / values.length);

    return {
      totalRows,
      median: median.toFixed(2),
      min: min.toFixed(2),
      max: max.toFixed(2),
      stdev: stdev.toFixed(2),
    };
  }, [exceptionType, data]);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header Section */}
      <div className="flex-grow">
        <div className="bg-white px-6 py-12 sm:py-16 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <img className="mx-auto h-16 w-auto" src={logo} alt="JHJW Consulting Ltd" />
            <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              JHJW Consulting Ltd
            </h2>
            <h3 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Exception Dashboard
            </h3>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Select an exception type and country code to view the relevant exception reports.
            </p>
          </div>

          {/* Form Section */}
          <div className="mt-10 mx-auto max-w-md">
            <div className="space-y-6">
              {/* Exception Type Selector */}
              <div>
                <label
                  htmlFor="exceptionType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Exception Type
                </label>
                <select
                  id="exceptionType"
                  name="exceptionType"
                  value={exceptionType}
                  onChange={handleExceptionTypeChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">--Select Exception Type--</option>
                  <option value="Unrated Races">Unrated Races</option>
                  <option value="General Exceptions">General Exceptions</option>
                  <option value="General Exception Summaries">General Exception Summaries</option>
                  <option value="History Vs Live">History Vs Live</option>
                </select>
              </div>

              {/* Conditional Country Code Selector */}
              {exceptionType && (
                <div>
                  <label
                    htmlFor="countryCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Country Code
                  </label>
                  {exceptionType === 'Unrated Races' ? (
                    <select
                      id="countryCode"
                      name="countryCode"
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">--Select Country Code--</option>
                      <option value="GB_F">GB_F</option>
                      <option value="GB_J">GB_J</option>
                      <option value="IRE_F">IRE_F</option>
                      <option value="IRE_J">IRE_J</option>
                      <option value="FR_F">FR_F</option>
                      <option value="All">All</option>
                    </select>
                  ) : (
                    <select
                      id="countryCode"
                      name="countryCode"
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">--Select Country Code--</option>
                      <option value="GB_F">GB_F</option>
                      <option value="GB_J">GB_J</option>
                      <option value="IRE_F">IRE_F</option>
                      <option value="IRE_J">IRE_J</option>
                      <option value="FR_F">FR_F</option>
                    </select>
                  )}
                </div>
              )}

              {exceptionType === 'Unrated Races' && (
                <>
                  <div>
                    <label
                      htmlFor="unratedFromDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      From Date
                    </label>
                    <input
                      type="date"
                      id="unratedFromDate"
                      name="unratedFromDate"
                      value={unratedFromDate}
                      onChange={(e) => setUnratedFromDate(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="unratedToDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      To Date
                    </label>
                    <input
                      type="date"
                      id="unratedToDate"
                      name="unratedToDate"
                      value={unratedToDate}
                      onChange={(e) => setUnratedToDate(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </>
              )}

              {/* Filter for History Vs Live */}
              {exceptionType === 'History Vs Live' && (
                <>
                  <div>
                    <label
                      htmlFor="historyFromDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      From Date
                    </label>
                    <input
                      type="date"
                      id="historyFromDate"
                      name="historyFromDate"
                      value={historyFromDate}
                      onChange={(e) => setHistoryFromDate(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="historyToDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      To Date
                    </label>
                    <input
                      type="date"
                      id="historyToDate"
                      name="historyToDate"
                      value={historyToDate}
                      onChange={(e) => setHistoryToDate(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </>
              )}

              {exceptionType === 'History Vs Live' && (
                <div>
                  <label htmlFor="exceptionTypeFilter" className="block text-sm font-medium text-gray-700">
                    Select Exception Type
                  </label>
                  <select
                    id="exceptionTypeFilter"
                    name="exceptionTypeFilter"
                    value={selectedExceptionType}
                    onChange={handleExceptionTypeFilterChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">--Select Exception Type--</option>
                    {exceptionTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          {/* Data Display Section */}
          {countryCode && (
            <div className="mt-12">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                Exception Report
              </h2>
              <p className="mt-2 text-lg text-gray-600">
                Exception Type: <strong>{exceptionType}</strong>
              </p>
              <p className="text-lg text-gray-600">
                Country Code: <strong>{countryCode}</strong>
              </p>


              {displayFromDate && (
                <p className="text-lg text-gray-600">
                  From Date: <strong>{displayFromDate}</strong>
                </p>
              )}
              {displayToDate && (
                <p className="text-lg text-gray-600">
                  To Date: <strong>{displayToDate}</strong>
                </p>
              )}

              {/* Summary Stats for Unrated Races */}
              {exceptionType === 'Unrated Races' && unratedSummary && (
                <div className="mt-4 text-lg text-gray-700">
                  <p>Total Rows: <strong>{unratedSummary.totalRows}</strong></p>
                  <p>Smart Rated: <strong>{unratedSummary.smartRatedPercent}%</strong></p>
                  <p>Smart Time Exists: <strong>{unratedSummary.smartTimeExistsPercent}%</strong></p>
                </div>
              )}

              {/* Summary Stats for History Vs Live */}
              {exceptionType === 'History Vs Live' && historyVsLiveSummary && (
                <div className="mt-4 text-lg text-gray-700">
                  <p>Total Rows: <strong>{historyVsLiveSummary.totalRows}</strong></p>
                  <p>Median: <strong>{historyVsLiveSummary.median}</strong></p>
                  <p>Min: <strong>{historyVsLiveSummary.min}</strong></p>
                  <p>Max: <strong>{historyVsLiveSummary.max}</strong></p>
                  <p>St. Dev: <strong>{historyVsLiveSummary.stdev}</strong></p>
                </div>
              )}

              {/* Data Table */}
              <div className="mt-6">
                {data ? (
                  <ExceptionDataTable data={data} exceptionType={exceptionType} />
                ) : (
                  <p>Loading data...</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
}

export default ExceptionDashboard;
