async function fetchWithAuth(endpoint, params, token, method = 'GET', options = {}) {
    let url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;
    
    const fetchOptions = {
        method,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        ...options
    };

    if ((method === 'POST' || method === 'PATCH' || method === 'PUT') && params) {
        fetchOptions.body = JSON.stringify(params);
    } else if (params && method === 'GET') {
        const queryString = new URLSearchParams(params).toString();
        url += `${url.includes('?') ? '&' : '?'}${queryString}`;
    }

    try {
        const response = await fetch(url, fetchOptions);
        
        if (!response.ok) {
            // If the server returns an error status code, throw an error
            const errorData = await response.json().catch(() => ({ error: response.statusText }));
            throw new Error(errorData.error || response.statusText || 'Unknown error occurred');
        }
        
        const contentType = response.headers.get("content-type");
        
        if (contentType && contentType.includes("application/json")) {
            return response.json();
        }
        
        return response.text();
    } catch (error) {
        throw error;
    }
}

export default fetchWithAuth;