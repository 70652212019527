import { useState } from 'react'

// To remain logged in for longer I can convert sessionStorage to localStorage
// To have another look at the guide
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

export default function useToken () {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')

    // console.log(tokenString)
    if (tokenString) {
      const userToken = JSON.parse(tokenString)
      return userToken?.token
    }
  }

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken.token)
  }

  return {
    setToken: saveToken,
    token
  }
}
