import React from 'react';

function generateHorseUrl(dataSource, horseName, horseID, horseSuf = '') {

  let url = '';

  if (dataSource === 'TF') {
    const stub1 = 'https://www.timeform.com/horse-racing/horse-form/';
    const stub2 = horseName.toLowerCase().replace(/\s+/g, '-');
    const stub3 = horseID.toString().padStart(12, '0');
    url = `${stub1}${stub2}/${stub3}`;
  } else if (dataSource === 'ATR' || !dataSource) {
    const stub = 'https://www.attheraces.com/form/horse/';
    url = `${stub}${horseName.toLowerCase().replace(/\s+/g, '-')}/${horseSuf}/${horseID}`;
  }

  return url;
}

const HorseLink = ({ horse }) => {
  const { horseName, horseID, horseSuf, dataSource } = horse;
  const horseUrl = generateHorseUrl(dataSource, horseName, horseID, horseSuf);

  return (
    <a
      href={horseUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="text-inherit hover:text-blue-500"
      style={{ textDecoration: 'none' }}
    >
      {horseName.toUpperCase()}
    </a>
  );
};

export default HorseLink;
