import React, { useState, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip';
import useToken from '../App/useToken'
import getRole from '../Utils/getRole'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import { getComparator, stableSort } from '../Utils/SortArray'
import formatRaceDate from '../Utils/FormatRaceDate'
import logo from '../../images/SR.svg';
import Footer from '../Footer/Footer';
import HorseSearch from './components/HorseSearch'
import HorseLink from '../Utils/HorseLink';

function getHeadCells(role) {
  const first = 
  [
    // {
    //   id: 'rank',
    //   numeric: true,
    //   label: 'Similarity',
    //   fixed: true,
    // },
    {
      id: 'horseName',
      numeric: false,
      label: 'Horse Name',
      fixed: true,
    },
    {
      id: 'ownerName',
      numeric: false,
      label: 'Owner',
      fixed: false,
    },
    {
      id: 'trainerName',
      numeric: false,
      label: 'Trainer',
      fixed: false,
    },
    {
      id: 'Sex',
      numeric: false,
      label: 'Sex',
      fixed: false,
    },
    {
      id: 'Age',
      numeric: false,
      label: 'Age',
      fixed: false,
    },
    {
      id: 'Course',
      numeric: false,
      label: 'Course',
      fixed: false,
    },  
    {
      id: 'raceDate',
      numeric: false,
      label: 'Race Date ',
      fixed: false,
    },  
    {
      id: 'raceTime',
      numeric: false,
      label: 'Race Time ',
      fixed: false,
    },
    // {
    //   id: 'smartHorseID',
    //   numeric: false,
    //   label: 'Smart Horse ID',
    //   fixed: false,
    // },
    // {
    //   id: '_id',
    //   numeric: false,
    //   label: 'Smart Race ID',
    //   fixed: false,
    // },
    {
      id: 'finishPosition',
      numeric: true,
      label: 'Fin Pos',
      fixed: false,
    },
    {
      id: 'SmartForm',
      numeric: true,
      label: 'Smart Form',
      fixed: false,
    },
    {
      id: 'SmartTime',
      numeric: true,
      label: 'Smart Time',
      fixed: false,
    },
    {
      id: 'SmartSecTime',
      numeric: true,
      label: 'Smart Sec Time',
    },
    {
      id: 'SmartRating',
      numeric: true,
      label: 'Smart Rating',
      fixed: false,
    },
    {
      id: 'SmartMasterPost',
      numeric: true,
      label: 'Smart Master',
      fixed: false,
    }
  ]

  const second = [
    // {
    //   id: 'UPGRADE',
    //   numeric: true,
    //   label: 'Upgrade',
    //   fixed: false,
    // },
    // {
    //   id: 'WindSpeed',
    //   numeric: true,
    //   label: 'WindSpeed',
    //   fixed: false,
    // },
    // {
    //   id: 'WindHead',
    //   numeric: true,
    //   label: 'WindHead',
    //   fixed: false,
    // },
    // {
    //   id: 'RainCumlOnHour',
    //   numeric: true,
    //   label: 'RainCumlOnHour',
    //   fixed: false,
    // },
    {
      id: 'Source',
      numeric: false,
      label: 'SecSource ',
      fixed: false,
      align: 'right',
    },
    {
      id: 'RankRnrs',
      numeric: true,
      label: 'RankRnrs',
      fixed: false,
    },
    {
      id: 'TimeRnk',
      numeric: true,
      label: 'TimeRank',
      fixed: false,
    },
    {
      id: 'SecRnk',
      numeric: true,
      label: 'SecRank',
      fixed: false,
    },
    {
      id: 'cadenceMax',
      numeric: true,
      label: 'CadenceMax',
      fixed: false,
    },
    {
      id: 'cadenceMaxRnk',
      numeric: true,
      label: 'CadenceMaxRank',
      fixed: false,
    },
    {
      id: 'stdLthMax',
      numeric: true,
      label: 'StdLthMax',
      fixed: false,
    },
    {
      id: 'stdLthRnk',
      numeric: true,
      label: 'StdLthRank',
      fixed: false,
    },
    // {
    //   id: 'rainAdjustment',
    //   numeric: true,
    //   label: 'rainAdjustment',
    //   fixed: false,
    // },
    // {
    //   id: 'windAdjustment',
    //   numeric: true,
    //   label: 'windAdjustment',
    //   fixed: false,
    // },
    {
      id: 'raceTitle',
      numeric: false,
      label: 'Race Title',
      fixed: false,
    },
  ]

  const third = [
    {
      id: '_id',
      numeric: false,
      label: 'Smart Race ID',
      fixed: false,
    },
  ]

  if (role === "admin" || role === "blandford") {
    if (role === "admin") {
      return first.concat(second, third);
    } else {
      return first.concat(second);
    }
  }  

  return first

}

function Search () {
  const [leaderBoard, setLeaderBoard] = useState([])
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const [order, setOrder] = useState('desc'); 
  const [orderBy, setOrderBy] = useState('raceDate');
  const [sortedRows, setSortedRows] = useState([]);

  const { token } = useToken()
  const role = getRole()

  const [horseName, setHorseName] = useState('');

  const handleSearch = (searchValue) => {
    setHorseName(searchValue); 
  };

  const loadRaces = (e) => {
    e.preventDefault()
  }

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };  

  useEffect(() => {
    const fetchLeaderBoard = async () => {
      if (!horseName) return;
  
      setIsLoaded(false);
      setError(null);
      const originalHorseName = horseName; 
      const modifiedHorseName = horseName.replace(/\s+/g, '').toUpperCase(); 
      
      const queryParamsOriginal = `horseName=${encodeURIComponent(originalHorseName)}&code=Both`;
      const queryParamsModified = `horseName=${encodeURIComponent(modifiedHorseName)}&code=Both`;
  
      const urlOriginal = `${process.env.REACT_APP_BACKEND_URL}SearchTable?${queryParamsOriginal}`;
      const urlModified = `${process.env.REACT_APP_BACKEND_URL}SearchTable?${queryParamsModified}`;
  
      try {
        
        const [responseOriginal, responseModified] = await Promise.all([
          fetch(urlOriginal, { headers: { "Authorization": `Bearer ${token}` } }),
          fetch(urlModified, { headers: { "Authorization": `Bearer ${token}` } })
        ]);
  
      
        if (!responseOriginal.ok || !responseModified.ok) {
          throw new Error(`HTTP error! status: ${responseOriginal.status} & ${responseModified.status}`);
        }
  
        // Parse JSON responses
        const resultOriginal = await responseOriginal.json();
        const resultModified = await responseModified.json();
  
        // Concatenate results, ensuring no duplicates
        const combinedResults = [...resultOriginal, ...resultModified.filter(item => !resultOriginal.some(originalItem => originalItem.horseID === item.horseID))];
  
        setLeaderBoard(combinedResults);
      } catch (error) {
        console.error("Failed to fetch leaderboard:", error);
        setError(error);
      } finally {
        setIsLoaded(true);
      }
    };
  
    fetchLeaderBoard();
  }, [horseName, token]); // Dependencies
  
  
  useEffect(() => {
    const sortAndFilterLeaderboard = () => {
      if (!horseName || leaderBoard.length === 0) return [];
  
      // Prepare the modified version of the horseName for comparison
      const modifiedHorseName = horseName.replace(/\s+/g, '').toUpperCase();
  
      const filtered = leaderBoard.filter(horse => {
        // Check both original and modified horse names against each horse in the leaderboard
        const horseNameLower = horse.horseName.toLowerCase();
        const horseNameWithoutSpaces = horse.horseName.replace(/\s+/g, '').toUpperCase();
        return horseNameLower.includes(horseName.toLowerCase()) || horseNameWithoutSpaces.includes(modifiedHorseName);
      });
  
      const withSimilarity = filtered.map(horse => ({
        ...horse,
        // Calculate similarity index based on the original horseName; adjust if needed for modifiedHorseName
        similarityIndex: horse.horseName.toLowerCase().indexOf(horseName.toLowerCase())
      }));
  
      const sorted = stableSort(withSimilarity, getComparator(order, orderBy)).map((horse, index) => ({
        ...horse,
        rank: index + 1
      }));
  
      return sorted;
    };
  
    setSortedRows(sortAndFilterLeaderboard());
  }, [horseName, leaderBoard, order, orderBy, token]); 
  
  
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center">
                      <img
                        className="mx-auto h-16 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                      <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">JHJW Consulting Ltd</h2>
                      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          Search Tool
                      </h2>
                      <p className="mt-4 text-lg leading-8 text-gray-600">
                          Information available at the performance level.
                      </p>
                    </div>

                    <div className="mx-auto w-56 text-center">
                      <form className="mt-6" onChange={loadRaces}>

                          

                          <HorseSearch onSearch={handleSearch} />

                          

                      </form>
                    </div>
                    </div>
            <div className="mt-8 mx-20">        
                <Paper sx={{ marginTop: '10px', width: '100%', overflow: 'hidden' }}>
                <TableContainer 
                  sx=
                    {{ 
                      // maxHeight: 600 
                    }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    enablePinning
                  >
                    <EnhancedTableHead
                      headCells={getHeadCells(role)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      isSearchPage={true}
                    />
                    <TableBody>
                      {sortedRows.map((horse) => {
                      return(
                        <TableRow 
                        hover
                        key={horse.id}
                        style={{ background: 'white' }}
                        sx={{ cursor: 'pointer' }}
                        >
                          {/* <TableCell
                          align='right'
                          sx={{
                            "background": "#f5f5f5", 
                          }}
                          >
                            {horse.rank}
                          </TableCell> */}
                          <TableCell
                            align='left'
                            sx={{
                              "position": "sticky",
                              "left": 0,
                              "background": "#f5f5f5",
                              "wordWrap": "break-word", 
                              "zIndex": 800,
                              "minWidth": 150, 
                            }}
                          >
                            <HorseLink key={horse.horseID} horse={horse} />
                          </TableCell>
                          <TableCell align='left'>
                            <Tooltip title={horse.ownerName}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                                {horse.ownerName === "NaN" ? '' : horse.ownerName}
                              </div>
                            </Tooltip>
                            </TableCell>
                            <TableCell align='left'>
                            <Tooltip title={horse.trainerName}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                                {horse.trainerName === "NaN" ? '' : horse.trainerName}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.Sex}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.Age}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.course}
                          </TableCell>
                          <TableCell
                          align='left'
                          style={{ minWidth: '130px' }}
                          >
                            {formatRaceDate(horse.raceDate)}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.raceTime}
                          </TableCell>
                          {/* <TableCell
                          align='left'
                          >
                            {horse.smartHorseID}
                          </TableCell> */}
                          {/* <TableCell
                          align='left'
                          >
                            {horse._id}
                          </TableCell> */}
                          <TableCell
                          align='right'
                          >
                            {horse.finishPosition}
                          </TableCell>
                          <TableCell 
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(Number(horse.SmartForm).toFixed(1)) ? '' : Number(horse.SmartForm).toFixed(1)}
                          </TableCell>
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartTime).toFixed(1)) ? '' : parseFloat(horse.SmartTime).toFixed(1)}
                          </TableCell>                    
                          <TableCell 
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(Number(horse.SmartSecTime).toFixed(1)) ? '' : Number(horse.SmartSecTime).toFixed(1)}
                          </TableCell>
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartRating).toFixed(1)) ? '' : parseFloat(horse.SmartRating).toFixed(1)}
                          </TableCell>
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartMasterPost).toFixed(1)) ? '' : parseFloat(horse.SmartMasterPost).toFixed(1)}
                          </TableCell>
                          {/* {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.UPGRADE).toFixed(1)) ? '' : Number(horse.UPGRADE).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.WindSpeed).toFixed(1)) ? '' : Number(horse.WindSpeed).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.WindHead).toFixed(1)) ? '' : Number(horse.WindHead).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.RainCumlOnHour).toFixed(1)) ? '' : Number(horse.RainCumlOnHour).toFixed(1)}
                            </TableCell>
                          : null } */}
                          {role === 'admin' || role === 'blandford' ? 
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {horse.Source !== 'NaN' && horse.Source}
                          </TableCell>
                          : null }
                          {role === 'admin' || role === 'blandford' ?
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.RankRnrs === 'NaN' || horse.RankRnrs == null ? '' : Number(horse.RankRnrs).toFixed(1)}
                            </TableCell> 
                          : null }
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.timeRnk === 'NaN' || horse.timeRnk == null ? '' : Number(horse.timeRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.secRnk === 'NaN' || horse.secRnk == null ? '' : Number(horse.secRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMax === 'NaN' || horse.cadenceMax == null ? '' : Number(horse.cadenceMax).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMaxRnk === 'NaN' || horse.cadenceMaxRnk == null ? '' : Number(horse.cadenceMaxRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthMax === 'NaN' || horse.stdLthMax == null ? '' : Number(horse.stdLthMax).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthRnk === 'NaN' || horse.stdLthRnk == null ? '' : Number(horse.stdLthRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          {/* {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.rainAdjustment).toFixed(1)) ? '' : Number(horse.rainAdjustment).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.windAdjustment).toFixed(1)) ? '' : Number(horse.windAdjustment).toFixed(1)}
                            </TableCell>
                          : null } */}
                          {role === 'admin' || role === "blandford" ?
                          <TableCell align="left">
                            <Tooltip title={horse.raceTitle}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                                {horse.raceTitle}
                              </div>
                            </Tooltip>
                          </TableCell>
                          : null }
                          {role === 'admin'?
                            <TableCell
                            align='left'
                            >
                              {horse._id}
                            </TableCell>
                          : null }
                        </TableRow>
                      )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Paper>
            </div>
        </div>        
      </div>
      <Footer />
    </div>
    
  )
}

export default Search
