import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useViewportWidth from './useViewportWidth';


export default function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, isSearchPage} = props;

    const screenWidth = useViewportWidth(); 
    const isMobileView = screenWidth < 1000;

    // Check if the 'raceCard' column exists in headCells
    // This col is only used for racecards
    const hasraceCardColumn = headCells.some(cell => cell.id === 'raceCard');

    const createSortHandler =
      (property) => (event) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow style={{ background: 'white' }}>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align || (headCell.numeric ? 'right' : 'left')}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                background: "var(--table-header-bg, #f5f5f5)", 
                ...(headCell.fixed && !isMobileView ? { 
                  position: "sticky",
                  left: headCell.id === 'horseName' && !isSearchPage ? '92px' : 0,
                  zIndex: 1000,
                } : {})
              }}
          >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }