import React from 'react'
import CountryRacecard from './components/CountryRacecard.js'
import Footer from '../Footer/Footer'
import RaceCardHeader from './components/RaceCardHeader.js'

const IRE_J = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <RaceCardHeader title="Ratings for jumps races in Ireland" />
        <div>
            <CountryRacecard text={{ countryCode: 'IRE_J' }}></CountryRacecard>
        </div>
    </div>
    </div>
      <Footer />
    </div>
  )
}

export default IRE_J
