import React from 'react';
import { useTheme } from '../../Theme/ThemeProvider';
import logo from '../../../images/SR.svg';
import CoolmoreLogo from '../../../images/CoolmoreLogo';

const clientLogos = {
  blandford: '/blandford-logo.png',
};

const ClientBranding = () => {
  const { theme, clientName } = useTheme();
  const isCustomClient = theme !== 'trial' && theme !== 'admin';
  const isBlandford = theme === 'blandford';
  const isCoolmore = theme === 'coolmore';
  
  return (
    <div className={`text-center ${
      isBlandford ? 'bg-[#fffdf0]' : 
      isCoolmore ? 'bg-white' : 
      ''
    }`}>
      <div className={`flex justify-center items-center gap-4 ${
        isBlandford || isCoolmore ? 'py-6' : ''
      }`}>
        <img
          className="h-24 w-auto"
          src={logo}
          alt="Smart Ratings"
        />
        
        {isCustomClient && (
          isCoolmore ? (
            <CoolmoreLogo className="h-24 w-auto" />
          ) : (
            <img
              className={`${
                isBlandford ? 'h-24' : 'h-16'
              } w-auto`}
              src={clientLogos[theme] || ''}
              alt={clientName}
              onError={(e) => {
                e.target.style.display = 'none';
                console.warn(`Logo for ${theme} theme not found`);
              }}
            />
          )
        )}
      </div>
      
      {isBlandford ? (
        <>
          <h4 className="mt-6 text-4xl font-bold tracking-tight text-[#2f2355] sm:text-6xl italic">
            {clientName}
          </h4>
          <div className="mt-3 text-xl font-medium tracking-tight text-[#2f2355] sm:text-2xl">
            <p>Newmarket Suffolk</p>
            <p className="mt-2 mb-6">Bloodstock Consultancy Services</p>
          </div>
          <div className="bg-[#2f2355] text-white py-3 mt-4 mb-8">
            <p className="text-lg font-medium italic">Excellence in Bloodstock Services</p>
            <p className="text-sm mt-1 font-light">Powered by JHJW Consulting Ltd - Smart Ratings</p>
          </div>
        </>
      ) : isCoolmore ? (
        <>
          {/* <h4 className="mt-6 text-4xl font-bold tracking-tight text-[#000c40] sm:text-6xl">
            {clientName}
          </h4> */}
          {/* <div className="mt-3 text-xl font-medium tracking-tight text-[#000c40] sm:text-2xl">
            <p>Home Of Champions</p>
            <p className="mt-2 mb-6">Ireland</p>
          </div> */}
          <div className="py-3 mt-4 mb-8">
            <p className="text-lg font-medium">SmartRatings - Automated Form, Time and Sectional Ratings</p>
            {/* <p className="text-sm mt-1 font-light">Powered by JHJW Consulting Ltd - Smart Ratings</p> */}
          </div>
        </>
      ) : isCustomClient ? (
        <>
          <h4 className="mt-6 text-4xl font-bold tracking-tight text-primary sm:text-6xl">
            {clientName}
          </h4>
          <h6 className="mt-3 text-2xl font-semibold tracking-tight text-gray-700 sm:text-3xl">
            JHJW Consulting Ltd
          </h6>
        </>
      ) : (
        <>
          <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            JHJW Consulting Ltd
          </h2>
          <h4 className="mt-6 text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            {clientName}
          </h4>
        </>
      )}
    </div>
  );
};

export default ClientBranding;