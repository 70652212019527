import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../components/ui/alert-dialog';
import { AlertCircle, Check, Key, LogOut, UserCircle, Mail, Trash2 } from 'lucide-react';
import fetchWithAuth from '../Utils/FetchWithAuth';
import DeleteConfirmation from './components/DeleteConfirmation';

const passwordCriteria = [
  { id: 'length', label: 'At least 8 characters', regex: /.{8,}/ },
  { id: 'uppercase', label: 'One uppercase letter', regex: /[A-Z]/ },
  { id: 'lowercase', label: 'One lowercase letter', regex: /[a-z]/ },
  { id: 'number', label: 'One number', regex: /\d/ },
  { id: 'special', label: 'One special character', regex: /[@$!%*?&]/ }
];

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showProfileConfirm, setShowProfileConfirm] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({});
  const history = useHistory();
  
  const [profileData, setProfileData] = useState({
    username: '',
    email: '',
    name: ''
  });
  
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [originalProfileData, setOriginalProfileData] = useState({});

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken.token;
    }
    return null;
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth('users/me', {}, getToken());
      setProfileData({
        username: response.username,
        email: response.email,
        name: response.name || ''  // Handle case where name might be undefined
      });
      setOriginalProfileData({
        username: response.username,
        email: response.email,
        name: response.name || ''
      });
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to load user data' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetchWithAuth('users/sessions', {}, getToken());
        setSessions(response.sessions || []);
      } catch (error) {
        console.error('Failed to fetch sessions:', error);
      }
    };

    if (activeTab === 'sessions') {
      fetchSessions();
    }
  }, [activeTab]);

  const validatePassword = (password) => {
    const validation = {};
    passwordCriteria.forEach(criterion => {
      validation[criterion.id] = criterion.regex.test(password);
    });
    setPasswordValidation(validation);
    return Object.values(validation).every(Boolean);
  };

  const handleProfileUpdate = async () => {
    setLoading(true);
    setMessage({ type: '', content: '' });
  
    try {
        const updatedFields = {};
        if (profileData.name !== originalProfileData.name) {
            updatedFields.name = profileData.name;
        }
        if (profileData.username !== originalProfileData.username) {
            updatedFields.username = profileData.username;
        }
        if (profileData.email !== originalProfileData.email) {
            updatedFields.email = profileData.email;
        }
  
        if (Object.keys(updatedFields).length === 0) {
            setMessage({ type: 'error', content: 'No changes to update' });
            setLoading(false);
            return;
        }

        console.log('Update payload:', updatedFields);
        const response = await fetchWithAuth('users/me', updatedFields, getToken(), 'PATCH');
        
        if (typeof response === 'string') {
            throw new Error('Invalid server response');
        }
  
        setMessage({ 
            type: 'success', 
            content: `Profile updated successfully${response.emailSent ? '. Confirmation email sent' : ''}`
        });
        setOriginalProfileData({...profileData});
        setShowProfileConfirm(false);
  
        // Update session storage with new user data
        if (response.user) {
            const tokenString = sessionStorage.getItem('token');
            if (tokenString) {
                const tokenData = JSON.parse(tokenString);
                tokenData.user = response.user;
                sessionStorage.setItem('token', JSON.stringify(tokenData));
            }
        }
    } catch (error) {
        console.error('Profile update error:', error);
        setMessage({ 
            type: 'error', 
            content: error.message || 'Failed to update profile' 
        });
    } finally {
        setLoading(false);
    }
};

  const handlePasswordChange = async () => {
    setLoading(true);
    setMessage({ type: '', content: '' });

    try {
      await fetchWithAuth('users/me/password', {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      }, getToken(), 'PATCH');
      
      setMessage({ type: 'success', content: 'Password updated successfully' });
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      setShowPasswordConfirm(false);
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to update password' });
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeSession = async (sessionId) => {
    try {
      await fetchWithAuth(`users/sessions/${sessionId}`, {}, getToken(), 'DELETE');
      const response = await fetchWithAuth('users/sessions', {}, getToken());
      setSessions(response.sessions || []);
      setMessage({ type: 'success', content: 'Session revoked successfully' });
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to revoke session' });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await fetchWithAuth('users/me', {}, getToken(), 'DELETE');
      sessionStorage.removeItem('token');
      history.push('/');
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to delete account' });
    }
  };

  const hasProfileChanges = () => {
    return JSON.stringify(profileData) !== JSON.stringify(originalProfileData);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Account Settings</h1>

        <div className="flex gap-6">
          {/* Sidebar Navigation */}
          <div className="w-64">
            <div className="bg-white rounded-lg shadow">
              <nav className="space-y-1">
                <button
                  onClick={() => setActiveTab('profile')}
                  className={`w-full flex items-center px-4 py-2 text-sm font-medium ${
                    activeTab === 'profile' 
                      ? 'bg-indigo-50 text-indigo-600' 
                      : 'text-gray-900 hover:bg-gray-50'
                  }`}
                >
                  <UserCircle className="mr-3 h-5 w-5" />
                  Profile
                </button>

                <button
                  onClick={() => setActiveTab('security')}
                  className={`w-full flex items-center px-4 py-2 text-sm font-medium ${
                    activeTab === 'security' 
                      ? 'bg-indigo-50 text-indigo-600' 
                      : 'text-gray-900 hover:bg-gray-50'
                  }`}
                >
                  <Key className="mr-3 h-5 w-5" />
                  Security
                </button>

                <button
                  onClick={() => setActiveTab('sessions')}
                  className={`w-full flex items-center px-4 py-2 text-sm font-medium ${
                    activeTab === 'sessions' 
                      ? 'bg-indigo-50 text-indigo-600' 
                      : 'text-gray-900 hover:bg-gray-50'
                  }`}
                >
                  <LogOut className="mr-3 h-5 w-5" />
                  Sessions
                </button>
              </nav>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="flex-1">
            <Card>
              <CardHeader>
                <CardTitle>
                  {activeTab === 'profile' && 'Profile Information'}
                  {activeTab === 'security' && 'Security Settings'}
                  {activeTab === 'sessions' && 'Active Sessions'}
                </CardTitle>
              </CardHeader>
              <CardContent>
                {message.content && (
                  <Alert className={`mb-4 ${message.type === 'success' ? 'bg-green-50' : 'bg-red-50'}`}>
                    <div className="flex items-center">
                      {message.type === 'success' ? (
                        <Check className="h-5 w-5 text-green-400" />
                      ) : (
                        <AlertCircle className="h-5 w-5 text-red-400" />
                      )}
                      <AlertDescription className="ml-2">
                        {message.content}
                      </AlertDescription>
                    </div>
                  </Alert>
                )}

                {/* Profile Tab */}
                {activeTab === 'profile' && (
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <input
                        type="text"
                        value={profileData.name}
                        onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <input
                        type="text"
                        value={profileData.username}
                        onChange={(e) => setProfileData({ ...profileData, username: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    {/* <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        value={profileData.email}
                        onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div> */}

                    <AlertDialog open={showProfileConfirm} onOpenChange={setShowProfileConfirm}>
                      <AlertDialogTrigger asChild>
                        <button
                          disabled={!hasProfileChanges() || loading}
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                        >
                          {loading ? 'Updating...' : 'Update Profile'}
                        </button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Confirm Profile Update</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to update your profile information?
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={handleProfileUpdate}>
                            Update
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                )}

                {/* Security Tab */}
                {activeTab === 'security' && (
                  <div className="space-y-6">
                    <div className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Current Password
                        </label>
                        <input
                          type="password"
                          value={passwordData.currentPassword}
                          onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          New Password
                        </label>
                        <input
                          type="password"
                          value={passwordData.newPassword}
                          onChange={(e) => {
                            setPasswordData({ ...passwordData, newPassword: e.target.value });
                            validatePassword(e.target.value);
                          }}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <div className="mt-2 space-y-2">
                          {passwordCriteria.map(criterion => (
                            <div
                              key={criterion.id}
                              className={`text-sm ${
                                passwordValidation[criterion.id]
                                  ? 'text-green-600'
                                  : 'text-gray-500'
                              }`}
                            >
                              <span className="mr-2">
                                {passwordValidation[criterion.id] ? '✓' : '○'}
                              </span>
                              {criterion.label}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          value={passwordData.confirmPassword}
                          onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <AlertDialog open={showPasswordConfirm} onOpenChange={setShowPasswordConfirm}>
                        <AlertDialogTrigger asChild>
                          <button
                            disabled={loading || !passwordData.currentPassword || !passwordData.newPassword || 
                                     passwordData.newPassword !== passwordData.confirmPassword ||
                                     !Object.values(passwordValidation).every(Boolean)}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                          >
                            {loading ? 'Updating...' : 'Change Password'}
                          </button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Confirm Password Change</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to change your password? You will need to use the new password for future logins.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={handlePasswordChange}>
                              Change Password
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>

                    <div className="pt-6 border-t border-gray-200">
                      <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Account</h3>
                      <p className="text-sm text-gray-500 mb-4">
                        Once you delete your account, there is no going back. Please be certain.
                      </p>
                      {!showDeleteConfirm ? (
                        <button
                          type="button"
                          onClick={() => setShowDeleteConfirm(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          Delete Account
                        </button>
                      ) : (
                        <DeleteConfirmation
                          username={profileData.username}
                          onConfirm={handleDeleteAccount}
                          onCancel={() => setShowDeleteConfirm(false)}
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* Sessions Tab */}
                {activeTab === 'sessions' && (
                  <div className="space-y-6">
                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                      <ul className="divide-y divide-gray-200">
                        {sessions.map((session) => (
                          <li key={session.id}>
                            <div className="px-4 py-4 flex items-center justify-between sm:px-6">
                              <div className="flex items-center">
                                <div className="ml-3">
                                  <p className="text-sm font-medium text-gray-900">
                                    {session.deviceInfo}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    Last active: {new Date(session.lastActive).toLocaleString()}
                                  </p>
                                  {session.current && (
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Current Session
                                    </span>
                                  )}
                                </div>
                              </div>
                              {!session.current && (
                                <button
                                  type="button"
                                  onClick={() => handleRevokeSession(session.id)}
                                  className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red active:bg-red-700 transition ease-in-out duration-150"
                                >
                                  Revoke
                                </button>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button
                      type="button"
                      onClick={() => handleRevokeSession('all')}
                      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Revoke All Other Sessions
                    </button>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;