import React, { useState } from 'react';
import logo from '../../images/SR.svg';
import Footer from '../Footer/Footer';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

const ForgotDetails = () => {
  const [identifier, setIdentifier] = useState('');
  const [resetType, setResetType] = useState('password');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('idle'); // idle, loading, success, error

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    setMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}users/forgot-details`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          identifier,
          resetType
        })
      });

      const data = await response.json();

      if (response.ok) {
        setStatus('success');
        setMessage(resetType === 'password' 
          ? 'If an account exists with this email/username, you will receive password reset instructions.'
          : 'If an account exists with this email/username, you will receive your username by email.');
      } else {
        throw new Error(data.error || 'Reset request failed');
      }
    } catch (error) {
      setStatus('error');
      setMessage(error.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Smart Ratings"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset Account Details
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">
                  Forgot your account details?
                </CardTitle>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email or Username
                    </label>
                    <input
                      type="text"
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                      placeholder="Enter your email or username"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      What would you like to reset?
                    </label>
                    <select
                      value={resetType}
                      onChange={(e) => setResetType(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="password">Reset Password</option>
                      <option value="username">Recover Username</option>
                    </select>
                  </div>

                  <button 
                    type="submit" 
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                      status === 'loading' ? 'opacity-75 cursor-not-allowed' : ''
                    }`}
                    disabled={status === 'loading'}
                  >
                    {status === 'loading' ? 'Processing...' : 'Submit'}
                  </button>
                </form>

                {message && (
                  <div className={`mt-4 text-sm ${
                    status === 'success' ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {message}
                  </div>
                )}

                <div className="mt-6 text-center">
                  <a 
                    href="/" 
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Back to Login
                  </a>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotDetails;