import React from 'react';
import { useTheme } from './ThemeProvider';

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50 bg-white p-2 rounded-lg shadow-lg border">
      <div className="flex flex-col gap-2">
        <p className="text-sm font-medium mb-1">Theme Switcher (Dev, admin, NEDS Only)</p>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => handleThemeChange('trial')}
            className={`px-2 py-1 rounded text-xs ${theme === 'trial' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
          >
            Trial
          </button>
          <button
            onClick={() => handleThemeChange('blandford')}
            className={`px-2 py-1 rounded text-xs ${theme === 'blandford' ? 'bg-green-700 text-white' : 'bg-gray-200'}`}
          >
            Client 1
          </button>
          <button
            onClick={() => handleThemeChange('admin')}
            className={`px-2 py-1 rounded text-xs ${theme === 'admin' ? 'bg-purple-700 text-white' : 'bg-gray-200'}`}
          >
            Admin
          </button>
          <button
            onClick={() => handleThemeChange('coolmore')}
            className={`px-2 py-1 rounded text-xs ${theme === 'coolmore' ? 'bg-teal-600 text-white' : 'bg-gray-200'}`}
          >
            Client 2
          </button>
          <button
            onClick={() => handleThemeChange('friend')}
            className={`px-2 py-1 rounded text-xs ${theme === 'friend' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
          >
            NEDS
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeSwitcher;