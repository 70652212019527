import React, { createContext, useContext, useEffect, useState } from 'react';
import getRole from '../Utils/getRole';

// Create a context for theme
export const ThemeContext = createContext({
  theme: 'trial',
  setTheme: () => {},
  clientName: 'Smart Ratings'
});

// Map roles to themes - all lowercase for consistency
const roleToTheme = {
  'trial': 'trial',
  'admin': 'admin',
  'blandford': 'blandford',
  'coolmore': 'coolmore',
  'friend': 'friend'
};

// Map themes to client names - all lowercase theme keys
const themeToClientName = {
  'trial': 'Smart Ratings',
  'admin': 'Smart Ratings Admin',
  'blandford': 'Blandford Bloodstock',
  'coolmore': 'Coolmore Stud',
  'friend': 'Smart Ratings NEDS'
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('trial');
  const [clientName, setClientName] = useState('Smart Ratings');

  // Apply theme changes
  const applyTheme = (newTheme) => {
    // Convert to lowercase for consistency
    const lowerTheme = newTheme ? newTheme.toLowerCase() : 'trial';
    // Use the mapped theme or default to trial
    const validTheme = roleToTheme[lowerTheme] || lowerTheme || 'trial';
    setTheme(validTheme);
    setClientName(themeToClientName[validTheme] || 'Smart Ratings');
    document.documentElement.setAttribute('data-theme', validTheme);
    
    // Store the theme preference (for development purposes only)
    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('dev_theme_preference', validTheme);
    }
  };

  // Custom setTheme function that also updates the client name
  const handleSetTheme = (newTheme) => {
    applyTheme(newTheme);
  };

  useEffect(() => {
    // Force clear any stored theme preference in all environments
    localStorage.removeItem('dev_theme_preference');

    // Get user role from session
    const userRole = getRole();
    
    // Map role to theme (case-insensitive for safety)
    const normalizedRole = userRole ? userRole.toLowerCase() : 'trial';
    const userTheme = roleToTheme[normalizedRole] || 'trial';
    
    // Apply the theme
    applyTheme(userTheme);
    
    // Clean up on unmount
    return () => {
      document.documentElement.removeAttribute('data-theme');
    };
  }, [sessionStorage.getItem('token')]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme, clientName }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme
export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;