// src/components/Admin/InviteUsers.js
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';

export default function InviteUsers() {
  const [invitationData, setInvitationData] = useState([{ email: '', username: '' }]);
  const [role, setRole] = useState('Trial');
  const [targetUrl, setTargetUrl] = useState('auto'); // Auto uses role-based determination
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const { token } = useToken();

  const handleInvitationChange = (index, field, value) => {
    const newData = [...invitationData];
    newData[index][field] = value;
    setInvitationData(newData);
  };

  const addInvitationRow = () => {
    setInvitationData([...invitationData, { email: '', username: '' }]);
  };

  const removeInvitationRow = (index) => {
    if (invitationData.length > 1) {
      const newData = [...invitationData];
      newData.splice(index, 1);
      setInvitationData(newData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResults([]);
  
    // Filter out any rows with empty emails
    const validInvitations = invitationData.filter(invite => invite.email.trim() !== '');
    
    if (validInvitations.length === 0) {
      setError('No valid email addresses provided');
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetchWithAuth(
        'invitations',
        { invitations: validInvitations, role, targetUrl },
        token,
        'POST'
      );
      console.log('Response:', response);
  
      if (response && response.message) {
        setError(response.message);
      } else if (Array.isArray(response)) {
        setResults(response);
      } else {
        setError('Invalid response format');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to send invitations');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center p-6">
      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            Invite Users
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                User Invitations
              </label>
              
              {invitationData.map((invite, index) => (
                <div key={index} className="flex flex-col md:flex-row gap-2 mb-2">
                  <div className="flex-1">
                    <input
                      type="email"
                      value={invite.email}
                      onChange={(e) => handleInvitationChange(index, 'email', e.target.value)}
                      placeholder="Email address"
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                      required
                    />
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      value={invite.username}
                      onChange={(e) => handleInvitationChange(index, 'username', e.target.value)}
                      placeholder="Username (optional)"
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => removeInvitationRow(index)}
                    className="px-3 py-2 text-red-600 hover:text-red-800"
                    disabled={invitationData.length === 1}
                  >
                    ✕
                  </button>
                </div>
              ))}
              
              <button
                type="button"
                onClick={addInvitationRow}
                className="mt-2 px-3 py-1 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
              >
                + Add Another
              </button>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Role</label>
              <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="w-full rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="Trial">Trial</option>
                <option value="blandford">Blandford</option>
                <option value="coolmore">Coolmore</option>
                <option value="friend">Friend</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Target URL</label>
              <select
                value={targetUrl}
                onChange={(e) => setTargetUrl(e.target.value)}
                className="w-full rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="auto">Auto (Based on Role)</option>
                <option value="https://smartratingsblandford.com">smartratingsblandford.com</option>
                <option value="https://smartratingscoolmore.com">smartratingscoolmore.com</option>
                <option value="https://dev-smartratings.com">dev-smartratings.com</option>
                <option value="http://localhost:3000">localhost:3000</option>
              </select>
            </div>

            {error && (
              <div className="text-red-600 text-sm">{error}</div>
            )}

            {results.length > 0 && (
              <div className="border rounded-md p-4 bg-gray-50">
                <h3 className="font-medium mb-2">Results:</h3>
                <ul className="space-y-1">
                  {results.map((result, index) => (
                    <li key={index} className="flex items-center">
                      <span className={result.status === 'success' ? 'text-green-600' : 'text-red-600'}>
                        {result.email}: {result.status}
                        {result.reason ? ` (${result.reason})` : ''}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-400"
            >
              {loading ? 'Sending...' : 'Send Invitations'}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}