import React, { useState, useEffect, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import useToken from '../App/useToken'
import getRole from '../Utils/getRole'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import formatRaceDate from '../Utils/FormatRaceDate'
import { getComparator, stableSort } from '../Utils/SortArray'
import logo from '../../images/SR.svg';
import Footer from '../Footer/Footer';
import HorseLink from '../Utils/HorseLink';


function getHeadCells(role) {
  const first = 
  [
    {
      id: 'rank',
      numeric: true,
      label: 'Rank',
      fixed: true,
    },
    {
      id: 'horseName',
      numeric: false,
      label: 'Horse Name',
      fixed: true,
    },
    {
      id: 'ownerName',
      numeric: false,
      label: 'Owner',
      fixed: false,
    },
    {
      id: 'trainerName',
      numeric: false,
      label: 'Trainer',
      fixed: false,
    },
    {
      id: 'OSEX',
      numeric: false,
      label: 'Sex',
      fixed: false,
    },
    {
      id: 'Age',
      numeric: false,
      label: 'Age',
      fixed: false,
    },
    {
      id: 'Course',
      numeric: false,
      label: 'Course',
      fixed: false,
    },  
    {
      id: 'raceDate',
      numeric: false,
      label: 'Race Date ',
      fixed: false,
    },  
    {
      id: 'raceTime',
      numeric: false,
      label: 'Race Time ',
      fixed: false,
    },
    // {
    //   id: 'smartHorseID',
    //   numeric: false,
    //   label: 'Smart Horse ID',
    //   fixed: false,
    // },
    // {
    //   id: '_id',
    //   numeric: false,
    //   label: 'Smart Race ID',
    //   fixed: false,
    // },
    {
      id: 'finishPosition',
      numeric: true,
      label: 'Fin Pos',
      fixed: false,
    },
    {
      id: 'SmartForm',
      numeric: true,
      label: 'Smart Form',
      fixed: false,
    },
    {
      id: 'SmartTime',
      numeric: true,
      label: 'Smart Time',
      fixed: false,
    }
  ]

  const second = [
    {
      id: 'SmartSecTime',
      numeric: true,
      label: 'Smart Sec Time',
    }
  ]

  const third = [
    {
      id: 'SmartRating',
      numeric: true,
      label: 'Smart Rating',
      fixed: false,
    },
    {
      id: 'SmartMasterPost',
      numeric: true,
      label: 'Smart Master',
      fixed: false,
    }
  ]


  const fourth = [
    // {
    //   id: 'UPGRADE',
    //   numeric: true,
    //   label: 'Upgrade',
    //   fixed: false,
    // },
    // {
    //   id: 'WindSpeed',
    //   numeric: true,
    //   label: 'WindSpeed',
    //   fixed: false,
    // },
    // {
    //   id: 'WindHead',
    //   numeric: true,
    //   label: 'WindHead',
    //   fixed: false,
    // },
    // {
    //   id: 'RainCumlOnHour',
    //   numeric: true,
    //   label: 'RainCumlOnHour',
    //   fixed: false,
    // },
    {
      id: 'Source',
      numeric: false,
      label: 'SecSource ',
      fixed: false,
      align: 'right',
    },
    {
      id: 'dataSource',
      numeric: false,
      label: 'Data Source', // DEBUGGING
      fixed: false,
    },
    {
      id: 'RankRnrs',
      numeric: true,
      label: 'RankRnrs',
      fixed: false,
    },
    {
      id: 'timeRnk',
      numeric: true,
      label: 'TimeRank',
      fixed: false,
    },
    {
      id: 'secRnk',
      numeric: true,
      label: 'SecRank',
      fixed: false,
    },
    {
      id: 'cadenceMax',
      numeric: true,
      label: 'CadenceMax',
      fixed: false,
    },
    {
      id: 'cadenceMaxRnk',
      numeric: true,
      label: 'CadenceMaxRank',
      fixed: false,
    },
    {
      id: 'stdLthMax',
      numeric: true,
      label: 'StdLthMax',
      fixed: false,
    },
    {
      id: 'stdLthRnk',
      numeric: true,
      label: 'StdLthRank',
      fixed: false,
    },
    // {
    //   id: 'rainAdjustment',
    //   numeric: true,
    //   label: 'rainAdjustment',
    //   fixed: false,
    // },
    // {
    //   id: 'windAdjustment',
    //   numeric: true,
    //   label: 'windAdjustment',
    //   fixed: false,
    // },
  ]

  const fifth = [
    {
      id: 'raceTitle',
      numeric: false,
      label: 'Race Title',
      fixed: false,
    }
  ]

  const sixth = [
    {
      id: '_id',
      numeric: false,
      label: 'Smart Race ID',
      fixed: false,
    },
  ]

  if (role === "admin" || role === "blandford") {
    if (role === "admin") {
      return first.concat(second, third, fourth, fifth, sixth);
    } else {
      return first.concat(second, third, fourth, fifth);
    }
  }  

  return first.concat(third, fifth)

}

const sexOptions = [
  'Male',
  'Female',
  'All',
];

const ageGroups = [
  '2yo',
  '3yo',
  '4yo Plus',
  'All Ages',
]

const dateFilter = [
  'Today',
  'Yesterday',
  'Last 2 Days',
  'Last 4 Days',
  'Last 7 Days',
  'Last 10 Days',
  'Last 14 Days',
  'Last 30 Days',
  // 'Last 90 Days',
  'This Year',
  'All',
];


function LeaderBoardsFlat() {
  const [selectedSex, setSelectedSex] = useState('');
  const [ageGroup, setAgeGroup] = useState();
  const [dateFilterSelected, setDateFilterSelected] = useState();
  
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [order, setOrder] = useState('asc'); 
  const [orderBy, setOrderBy] = useState('rank');
  const [sortedRows, setSortedRows] = useState([]);

  const { token } = useToken();
  const role = getRole();

  useEffect(() => {
    if (selectedSex && ageGroup && dateFilterSelected) {
      const queryParams = `code=Flat&OSEX=${selectedSex}&OAGE=${ageGroup}&dateFilter=${dateFilterSelected}`;
  
      fetch(`${process.env.REACT_APP_BACKEND_URL}LeaderBoardFlat?${queryParams}`, {
        headers: { "Authorization": `Bearer ${token}` }
      })
      .then(res => res.json())
      .then(result => {
        const normalizedLeaderBoard = result.map(entry => ({
          ...entry,
          SmartRating: (entry.SmartRating === null || entry.SmartRating === undefined || isNaN(Number(entry.SmartRating))) ? 0 : Number(entry.SmartRating),
        }));
  
        const rankedBySmartRating = normalizedLeaderBoard.sort((a, b) => b.SmartRating - a.SmartRating)
          .map((horse, index) => ({
            ...horse,
            rank: index + 1,
            SmartRating: horse.SmartRating === 0 ? "" : horse.SmartRating
          }));
  
        setLeaderBoard(rankedBySmartRating);
      })
      .catch(error => setError(error))
      .finally(() => setIsLoaded(true));
    }
  }, [selectedSex, ageGroup, dateFilterSelected, token]);

  useEffect(() => {
    if (leaderBoard.length > 0) {
      let filteredAndSortedLeaderboard = leaderBoard.filter(horse => {
        if (selectedSex === 'All') return true;
        return selectedSex === 'Male' ? ['C', 'G', 'H'].includes(horse.Sex) :
               selectedSex === 'Female' ? ['M', 'F'].includes(horse.Sex) : false;
      });
  
      // Specific sorting for cols filled with text
      if (['finishPosition', 'trainerName', 'ownerName', 'SmartSecTime', 'Source', 'dataSource', 'timeRank', 'secRnk', 'cadenceMax', 'stdLthMax', 'stdLthRnk'].includes(orderBy)) {
        const [emptyValuesHorses, nonEmptyValuesHorses] = filteredAndSortedLeaderboard.reduce(([empty, nonEmpty], horse) => {
          const isEmpty = !horse[orderBy] && horse[orderBy] !== 0;
          return isEmpty ? [[...empty, horse], nonEmpty] : [empty, [...nonEmpty, horse]];
        }, [[], []]);

        filteredAndSortedLeaderboard = [...stableSort(nonEmptyValuesHorses, getComparator(order, orderBy)), ...emptyValuesHorses];
      } else {
        filteredAndSortedLeaderboard = stableSort(filteredAndSortedLeaderboard, getComparator(order, orderBy));
      }

      setSortedRows(filteredAndSortedLeaderboard);
    } else {
      setSortedRows([]);
    }
  }, [leaderBoard, selectedSex, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const loadRaces = (e) => {
    e.preventDefault();
  };
    
  
  

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center">
                      <img
                        className="mx-auto h-16 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                      <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">JHJW Consulting Ltd</h2>
                      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          Leaderboard Flat
                      </h2>
                      <p className="mt-4 text-lg leading-8 text-gray-600">
                          Information available at the performance level.
                      </p>
                    </div>

                    <div className="mx-auto w-56 text-center">
                      <form onChange={loadRaces}>
                      <label htmlFor="dateFilter" className='mt-6 block text-sm font-medium leading-6 text-gray-900'>Date Filter:</label>
                        <select
                            id="dateFilter"
                            name="dateFilter"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setDateFilterSelected(e.target.value)}
                        >
                            <option value="">Select Date Filter</option>
                            {dateFilter.map((filter) => (
                                <option key={filter} value={filter}>
                                    {filter}
                                </option>
                            ))}
                        </select>

                        <label htmlFor="ageGroup" className='mt-4 block text-sm font-medium leading-6 text-gray-900'>Age Group:</label>
                        <select
                            id="ageGroup"
                            name="ageGroup"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setAgeGroup(e.target.value)}
                        >
                            <option value="">Select Age Group</option>
                            {ageGroups.map((ageGroup) => (
                            <option key={ageGroup} value={ageGroup}>
                                {ageGroup}
                            </option>
                            ))}
                        </select>

                        <label htmlFor="sex" className='mt-4 block text-sm font-medium leading-6 text-gray-900'>Sex:</label>
                        <select
                            id="sex"
                            name="sex"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setSelectedSex(e.target.value)} 
                        >
                            <option value="">Select Sex</option>
                            {sexOptions.map((sexOption) => (
                            <option key={sexOption} value={sexOption}>
                                {sexOption}
                            </option>
                            ))}
                        </select>

                      </form>
                    </div>
                    </div>
            <div className="mt-8 mx-20">        
                <Paper sx={{ marginTop: '10px', width: '100%', overflow: 'hidden' }}>
                <TableContainer 
                  // sx={{ maxHeight: 600 }}
                >
                  <Table
                  stickyHeader
                  aria-label="sticky table"
                  enablePinning
                  >
                    <EnhancedTableHead
                    headCells={getHeadCells(role)}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {sortedRows.map((horse) => {
                      return(
                        <TableRow 
                        hover
                        key={horse.id}
                        style={{ background: 'white' }}
                        sx={{ cursor: 'pointer' }}
                        >
                          <TableCell
                          align='right'
                          sx={{
                            "background": "#f5f5f5", 
                          }}
                          >
                            {horse.rank}
                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              "position": "sticky",
                              "left": 0,
                              "background": "#f5f5f5",
                              "wordWrap": "break-word", 
                              "zIndex": 800,
                            }}
                          >
                            <HorseLink key={horse.horseID} horse={horse} />
                          </TableCell>
                          <TableCell align='left'>
                            <Tooltip title={horse.ownerName}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                              {horse.ownerName}
                              </div>
                            </Tooltip>
                            </TableCell>
                            <TableCell align='left'>
                            <Tooltip title={horse.trainerName}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                              {horse.trainerName}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.Sex}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.Age}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.course}
                          </TableCell>
                          <TableCell
                          align='left'
                          style={{ minWidth: '130px' }}
                          >
                            {formatRaceDate(horse.raceDate)}
                          </TableCell>
                          <TableCell
                          align='left'
                          >
                            {horse.raceTime}
                          </TableCell>
                          {/* <TableCell
                          align='left'
                          >
                            {horse.smartHorseID}
                          </TableCell> */}
                          {/* <TableCell
                          align='left'
                          >
                            {horse._id}
                          </TableCell> */}
                          <TableCell
                          align='right'
                          >
                            {horse.finishPosition}
                          </TableCell>
                          <TableCell 
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(Number(horse.SmartForm).toFixed(1)) ? '' : Number(horse.SmartForm).toFixed(1)}
                          </TableCell>
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartTime)) ? '' : parseFloat(horse.SmartTime).toFixed(1)}
                          </TableCell>
                          {role === 'admin' || role === 'blandford'?
                            <TableCell 
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {isNaN(Number(horse.SmartSecTime).toFixed(1)) ? '' : Number(horse.SmartSecTime).toFixed(1)}
                            </TableCell>
                          : null }
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartRating)) ? '' : parseFloat(horse.SmartRating).toFixed(1)}
                          </TableCell> 
                          <TableCell
                          align='right'
                          style={{ fontWeight: 'bold' }}
                          >
                            {isNaN(parseFloat(horse.SmartMasterPost)) ? '' : parseFloat(horse.SmartMasterPost).toFixed(1)}
                          </TableCell>
                          {/* {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.UPGRADE).toFixed(1)) ? '' : Number(horse.UPGRADE).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.WindSpeed).toFixed(1)) ? '' : Number(horse.WindSpeed).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.WindHead).toFixed(1)) ? '' : Number(horse.WindHead).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.RainCumlOnHour).toFixed(1)) ? '' : Number(horse.RainCumlOnHour).toFixed(1)}
                            </TableCell>
                          : null } */}
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.Source !== 'NaN' && horse.Source}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell 
                            align='left'
                            style={{ fontWeight: 'bold' }}
                            >
                            {horse.dataSource ? horse.dataSource : 'Unknown'}
                          </TableCell>
                          : null }
                          {role === 'admin' || role === 'blandford' ?
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.RankRnrs === 'NaN' || horse.RankRnrs == null ? '' : Number(horse.RankRnrs).toFixed(1)}
                            </TableCell> 
                          : null }
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.timeRnk === 'NaN' || horse.timeRnk == null ? '' : Number(horse.timeRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === 'blandford' ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.secRnk === 'NaN' || horse.secRnk == null ? '' : Number(horse.secRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMax === 'NaN' || horse.cadenceMax == null ? '' : Number(horse.cadenceMax).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMaxRnk === 'NaN' || horse.cadenceMaxRnk == null ? '' : Number(horse.cadenceMaxRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthMax === 'NaN' || horse.stdLthMax == null ? '' : Number(horse.stdLthMax).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin' || role === "blandford" ? 
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthRnk === 'NaN' || horse.stdLthRnk == null ? '' : Number(horse.stdLthRnk).toFixed(1)}
                            </TableCell>
                          : null }
                          <TableCell align="left">
                            <Tooltip title={horse.raceTitle}>
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                                {horse.raceTitle}
                              </div>
                            </Tooltip>
                          </TableCell>
                          {role === 'admin'?
                            <TableCell
                            align='left'
                            >
                              {horse._id}
                            </TableCell>
                          : null }
                          {/* {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.rainAdjustment).toFixed(1)) ? '' : Number(horse.rainAdjustment).toFixed(1)}
                            </TableCell>
                          : null }
                          {role === 'admin'? 
                            <TableCell
                            align='right'
                            >
                              {isNaN(Number(horse.windAdjustment).toFixed(1)) ? '' : Number(horse.windAdjustment).toFixed(1)}
                            </TableCell>
                          : null } */}
                        </TableRow>
                      )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Paper>
            </div>
        </div>        
      </div>
      <Footer />
    </div>
    
  )
}

export default LeaderBoardsFlat
