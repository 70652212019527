import React from 'react'
import CountryRacecard from './components/CountryRacecard.js'
import Footer from '../Footer/Footer.js'
import RaceCardHeader from './components/RaceCardHeader.js'

// Custom columns configuration for FR_F race cards
const fr_f_hidden_columns = [
  'ORpost', 
  'RPRpost', 
  'TFpost', 
  'Source', 
  'RankRnrs', 
  'timeRnk', 
  'cadenceMax', 
  'cadenceMaxRnk', 
  'stdLthMax', 
  'stdLthRnk',
  'TFtfigPost',
  'secRnk'
]

const FR_F = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <RaceCardHeader title="Ratings for flat races in France" />
        <div>
            <CountryRacecard text={{ countryCode: 'FR_F' }} hiddenColumns={fr_f_hidden_columns}></CountryRacecard>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FR_F
