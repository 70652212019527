import React from 'react';
import { generateHorseUrl } from './RaceUtils';

const HorseLink = ({ horse }) => {
  const { 
    horseName, 
    horseID, 
    horseSuf, 
    dataSource,
    raceDate,
    course,
    raceTime,
    country = 'FR' // Default to FR for LeaderboardFlat_FR 
  } = horse;

  // Use raceTime as time parameter if it exists
  const time = raceTime || '';

  // Generate the horse URL
  let horseUrl = generateHorseUrl(
    dataSource, 
    horseName, 
    horseID, 
    horseSuf,
    raceDate,
    course,
    time,
    country
  );

  // If URL generation failed and we have a horse name, provide a fallback
  if (!horseUrl && horseName) {
    if (country === 'FR') {
      // For French horses, always use France-Galop
      horseUrl = 'https://www.france-galop.com/en';
    } else if (country === 'GB' || country === 'IRE') {
      // For GB/IRE horses, try ATR as fallback
      const stub = 'https://www.attheraces.com/form/horse/';
      const safeHorseName = horseName.toLowerCase().replace(/\s+/g, '-');
      horseUrl = `${stub}${safeHorseName}`; // Minimal URL without ID/suffix
    }
  }

  // If we have a URL, render a link
  if (horseUrl) {
    return (
      <a
        href={horseUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-inherit hover:text-blue-500"
        style={{ textDecoration: 'none' }}
      >
        {horseName.toUpperCase()}
      </a>
    );
  } 
  
  // If no URL could be generated, just show the horse name without a link
  return <span className="text-inherit">{horseName.toUpperCase()}</span>;
};

export default HorseLink;
