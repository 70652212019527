import React, { useState, useEffect } from 'react'
import getCurrentDate from '../../Utils/GetCurrentDate'
import useToken from '../../App/useToken'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../../Utils/EnhancedTableHead';
import { getComparator, stableSort } from '../../Utils/SortArray'
import fetchWithAuth from '../../Utils/FetchWithAuth';

const statusHeadCells = [
  {
    id: '_id',
    numeric: false,
    label: 'Smart Race ID',
  },
  {
    id: 'raceTime',
    numeric: false,
    label: 'Race Time',
  },
  {
    id: 'RreRaceRated',
    numeric: true,
    label: 'Pre Race Rated',
  },
  {
    id: 'SmartRated',
    numeric: true,
    label: 'Smart Rated',
  },
  {
    id: 'WinSmartTimeExists',
    numeric: true,
    label: 'Smart Time Exists',
  },
];


function StatusDashboard () {
  const [statusVector, setStatusVector] = useState([])
  const [todaysDate, setTodaysDate] = useState(null)
  const { token } = useToken()
  const [order, setOrder] = useState('asc'); 
  const [orderBy, setOrderBy] = useState('_id');
  const [sortedRows, setSortedRows] = useState([]);

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = async () => {
    const IRE_F = await fetchWithAuth('raceStatusDay', {countryCode: 'IRE_F', raceDate: todaysDate}, token)
    const GB_F = await fetchWithAuth('raceStatusDay', {countryCode: 'GB_F', raceDate: todaysDate}, token)
    const IRE_J = await fetchWithAuth('raceStatusDay', {countryCode: 'IRE_J', raceDate: todaysDate}, token)
    const GB_J = await fetchWithAuth('raceStatusDay', {countryCode: 'GB_J', raceDate: todaysDate}, token)
    const statusVectorCon = [...IRE_F, ...GB_F, ...IRE_J, ...GB_J]
    const statusVectorSort = statusVectorCon.sort((a, b) => parseFloat(a.raceTime.replace('-', '.')) - parseFloat(b.raceTime.replace('-', '.')))
    setStatusVector(statusVectorSort)
  }

  useEffect(() => {
    const date = getCurrentDate()
    setTodaysDate(date)
    if (todaysDate) {
      fetchData()
    }
  }, [todaysDate])

  useEffect(
    () =>{
    if (statusVector){
      setSortedRows(stableSort(statusVector, getComparator(order, orderBy)))
    }
  }, [order, orderBy, statusVector]);

  return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer 
          sx={{ maxHeight: 600 }}
          >
            <Table
            stickyHeader 
            aria-label="sticky table"
            >
              <EnhancedTableHead
              headCells={statusHeadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedRows.map((status, index) => {
                  return(
                  <TableRow 
                    hover
                    // onClick={(event) => handleClick(event, status.id)}
                    key={status._id || `row-${index}`} /* Use _id or generate a unique key based on index */
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                      {status._id}
                    </TableCell>
                    <TableCell className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                      {status.raceTime}
                    </TableCell>
                    <TableCell 
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                    align='right'
                    >
                      <span className={status.PreRaceRated === 1
                        ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                        : 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'}>
                        {status.PreRaceRated}
                      </span>
                    </TableCell>
                    <TableCell 
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                    align='right'
                    >
                      <span className={status.SmartRated === 1
                        ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                        : 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'}>
                        {status.SmartRated}
                      </span>
                    </TableCell>
                    <TableCell 
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                    align='right'
                    >
                      <span className={status.WinSmartTimeExist === 1
                        ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                        : 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'}>
                        {status.WinSmartTimeExist}
                      </span>
                    </TableCell>
                  </TableRow>
              )})}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
  )
}

export default StatusDashboard
