import React, { useState, useEffect } from 'react';
import useToken from '../../App/useToken'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce'

function HorseSearchFR({ onSearch }) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const { token } = useToken();

  const fetchHorseNames = async (searchValue) => {
    if (!token) return; 
  
    try {
      const queryParams = new URLSearchParams({ query: searchValue }).toString();
      const url = `${process.env.REACT_APP_BACKEND_URL}SearchInputFieldFR?${queryParams}`;
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const uniqueHorseNames = new Set();
      
      const uniqueOptions = data.filter(horse => {
        const horseNameLower = horse.horseName.toLowerCase();
        if (!uniqueHorseNames.has(horseNameLower)) {
          uniqueHorseNames.add(horseNameLower);
          return true;
        }
        return false;
      });
      setOptions(uniqueOptions);
    } catch (error) {
      console.error('Failed to fetch horse names:', error);
      setOptions([]);
    }
  };
  
  
  const debouncedFetchHorseNames = debounce(fetchHorseNames, 300);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch(inputValue); 
    }
  };

  const handleSearchButtonClick = (event) => {
    event.preventDefault(); 
    onSearch(inputValue);
  };

  useEffect(() => {
    if (inputValue) {
      debouncedFetchHorseNames(inputValue);
    } else {
      setOptions([]);
    }

    // Cleanup
    return () => {
      debouncedFetchHorseNames.cancel();
    };
  }, [inputValue, token]);

  return (
    <div className="flex items-center">
      <Autocomplete
        freeSolo
        options={options.map((option) => option.horseName)}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Horse" variant="outlined" onKeyDown={handleKeyDown} style={{ minWidth: '200px' }}/>
        )}
      />
      <button
        type="button"
        className="ml-2 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-blue-600 rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-200"
        onClick={handleSearchButtonClick}
      >
        Search
      </button>
    </div>
  );
}

export default HorseSearchFR;
