import React from 'react';
import { useTheme } from '../../Theme/ThemeProvider';
import logo from '../../../images/SR.svg';
import CoolmoreLogo from '../../../images/CoolmoreLogo';

const clientLogos = {
  blandford: '/blandford-logo.png',
};

const LeaderboardHeader = ({ title, subtitle }) => {
  const { theme, clientName } = useTheme();
  const isCustomClient = theme !== 'Trial' && theme !== 'admin';
  
  return (
    <div className="text-center">
      <div className="flex justify-center items-center gap-4">
        <img
          className="h-16 w-auto"
          src={logo}
          alt="Smart Ratings"
        />
        
        {isCustomClient && (
          theme === 'coolmore' ? (
            <CoolmoreLogo className="h-16 w-auto" />
          ) : (
            <img
              className="h-16 w-auto"
              src={clientLogos[theme] || ''}
              alt={clientName}
              onError={(e) => {
                e.target.style.display = 'none';
                console.warn(`Logo for ${theme} theme not found`);
              }}
            />
          )
        )}
      </div>
      
      {isCustomClient ? (
        <>
          {/* <h4 className="mt-6 text-4xl font-bold tracking-tight text-primary sm:text-6xl">
            {clientName}
          </h4> */}
          <h6 className="mt-3 text-2xl font-semibold tracking-tight text-gray-700 sm:text-3xl">
            JHJW Consulting Ltd
          </h6>
        </>
      ) : (
        <>
          <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            JHJW Consulting Ltd
          </h2>
          <h4 className="mt-6 text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            {clientName}
          </h4>
        </>
      )}
      
      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {title}
      </h2>
      {subtitle && (
        <p className="mt-4 text-lg leading-8 text-gray-600">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default LeaderboardHeader;