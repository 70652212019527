import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../Login/Login';
import Register from '../Admin/Register';
import InviteUsers from '../Admin/InviteUsers';
import ManageInvitations from '../Admin/ManageInvitations';
import ManageUsers from '../Admin/ManageUsers';
import ForgotDetails from '../Login/ForgotDetails';
import ResetPassword from '../Login/ResetPassword';
import useToken from './useToken';
import getRole from '../Utils/getRole';
import Home from '../Home/Home';
import IRE_F from '../RaceCardTabs/IRE_F';
import GB_F from '../RaceCardTabs/GB_F'; 
import FR_F from '../RaceCardTabs/FR_F';
import IRE_J from '../RaceCardTabs/IRE_J';
import GB_J from '../RaceCardTabs/GB_J';
import NavBar from '../Navigation/NavigationTailwind';
import GoingAdjustments from '../GoingAdjustments/GoingAdjustments';
import GoingAdjustmentsFR from '../GoingAdjustments/GoingAdjustmentsFR';
import LeaderBoardsFlat from '../LeaderBoards/LeaderBoardsFlat';
import LeaderBoardsFlat_FR from '../LeaderBoards/LeaderBoardsFlat_FR';
import LeaderBoardsJump from '../LeaderBoardJump/LeaderBoardsJump';
import Search from '../Search/Search';
import ExceptionDashboard from '../Exceptions/ExceptionDashboard';
import SearchFR from '../Search/SearchFR';
import AccountSettings from '../AccountSettings/AccountSettings';
import SupportPortal from '../Support/SupportPortal';
import Glossary from '../Glossary/Glossary';
import { ThemeProvider } from '../Theme/ThemeProvider';
import ThemeSwitcher from '../Theme/ThemeSwitcher';

function App() {
 const { token, setToken } = useToken();
 const userRole = token ? getRole() : 'Trial';

 useEffect(() => {
   if (window.location.href === 'https://smart-ratings-fe.herokuapp.com/') {
     window.location.href = 'https://smartratingsblandford.com/';
   }
 }, []);

 if (!token) {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/forgot-details" component={ForgotDetails} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/register" component={Register} />
          <Route path="/" component={() => <Login setToken={setToken} />} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

 return (
   <ThemeProvider>
     <BrowserRouter forceRefresh>
       <div>
         <NavBar />
         <Switch>
           <Route path="/" component={Home} exact/>
           <Route path="/IRE_F" component={IRE_F}/>
           <Route path="/GB_F" component={GB_F}/>
           <Route path="/FR_F" component={FR_F}/>
           <Route path="/IRE_J" component={IRE_J}/>
           <Route path="/GB_J" component={GB_J}/>
           <Route path="/GoingAdjustments" component={GoingAdjustments}/>
           <Route path="/GoingAdjustmentsFR" component={GoingAdjustmentsFR}/>
           <Route path="/LeaderBoardFlat" component={LeaderBoardsFlat}/>
           <Route path="/LeaderBoardFlat_FR" component={LeaderBoardsFlat_FR}/>
           <Route path="/Search" component={Search}/>
           <Route path="/SearchFR" component={SearchFR}/>
           <Route path="/LeaderBoardJump" component={LeaderBoardsJump}/>
           {/* <Route path="/ExceptionDashboard" component={ExceptionDashboard}/> 
              <Route path="/ExceptionDashboardCards" component={ExceptionDashboardCards}/>
              <Route path="/ExceptionDashboardWidgets" component={ExceptionDashboardWidgets}/> */}
           <Route path="/ExceptionDashboard" component={ExceptionDashboard}/>
           <Route path="/invite" component={InviteUsers}/>
           <Route path="/support" component={SupportPortal} />
           <Route path="/account-settings" component={AccountSettings} />
           <Route path="/manage-invitations" component={ManageInvitations} />
           <Route path="/manage-users" component={ManageUsers} />
           <Route path="/glossary" component={Glossary} />
         </Switch>
         {(process.env.NODE_ENV === 'development' || window.location.href.includes('dev-smartratings.com')) && (userRole === 'admin' || userRole === 'friend') && <ThemeSwitcher />}
       </div>
     </BrowserRouter>
   </ThemeProvider>
 );
}

export default App;