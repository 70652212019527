// src/components/Admin/ManageUsers.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';
import { getComparator, stableSort } from '../Utils/SortArray';

export default function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [actionResult, setActionResult] = useState({ message: '', type: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const { token } = useToken();
  
  const getCurrentUserEmail = () => {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString) {
      const userData = JSON.parse(tokenString);
      return userData.user?.email || '';
    }
    return '';
  };
  
  const currentUserEmail = getCurrentUserEmail();

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await fetchWithAuth(
        'users',
        null,
        token,
        'GET'
      );
      
      if (Array.isArray(response)) {
        setUsers(response);
        setFilteredUsers(response);
      } else {
        setError('Invalid response format');
      }
    } catch (err) {
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter users based on search term
    if (searchTerm.trim() === '') {
      setFilteredUsers(users);
    } else {
      const term = searchTerm.toLowerCase();
      const filtered = users.filter(user => 
        (user.name && user.name.toLowerCase().includes(term)) || 
        (user.username && user.username.toLowerCase().includes(term)) || 
        (user.email && user.email.toLowerCase().includes(term)) ||
        (user.role && user.role.toLowerCase().includes(term))
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, users]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRemoveUser = async (userId, identifier) => {
    if (!window.confirm(`Are you sure you want to remove this user "${identifier}"? This action cannot be undone.`)) {
      return;
    }

    setLoading(true);
    setActionResult({ message: '', type: '' });
    
    try {
      const response = await fetchWithAuth(
        `users/${userId}`,
        {},
        token,
        'DELETE'
      );
      
      if (response && response.message) {
        setActionResult({ message: response.message, type: 'success' });
        // Refresh the list
        fetchUsers();
      } else if (response && response.error) {
        setActionResult({ message: response.error, type: 'error' });
      } else {
        setActionResult({ message: 'Unexpected response from server', type: 'error' });
      }
    } catch (err) {
      setActionResult({ 
        message: err.message || 'Failed to remove user', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleEditRole = (userId, currentRole) => {
    setEditingUserId(userId);
    setSelectedRole(currentRole || 'trial');
  };
  
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  
  const isAdminRoleAllowed = (email) => {
    return email && email.endsWith('@jhjwconsulting.com');
  };
  
  const canUserChangeRole = (user) => {
    // Only sam.dolbear@jhjwconsulting.com can demote admins
    if ((user.role || '').toLowerCase() === 'admin' && currentUserEmail !== 'sam.dolbear@jhjwconsulting.com') {
      return false;
    }
    return true;
  };
  
  const handleSaveRole = async (userId, email) => {
    // Validate role change
    if (selectedRole === 'admin' && !isAdminRoleAllowed(email)) {
      setActionResult({ 
        message: 'Only users with @jhjwconsulting.com email can be admins', 
        type: 'error' 
      });
      return;
    }
    
    setLoading(true);
    setActionResult({ message: '', type: '' });
    
    try {
      const response = await fetchWithAuth(
        `users/${userId}/role`,
        { role: selectedRole },
        token,
        'PUT'
      );
      
      if (response && response.message) {
        const sessionMessage = response.sessionInvalidated ? 
          ' User will be forced to log in again with their new role.' : '';
        
        setActionResult({ 
          message: response.message + sessionMessage, 
          type: 'success' 
        });
        
        // Refresh the list
        fetchUsers();
      } else if (response && response.error) {
        setActionResult({ message: response.error, type: 'error' });
      } else {
        setActionResult({ message: 'Unexpected response from server', type: 'error' });
      }
    } catch (err) {
      setActionResult({ 
        message: err.message || 'Failed to update user role', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
      setEditingUserId(null);
    }
  };
  
  const handleCancelEdit = () => {
    setEditingUserId(null);
    setSelectedRole('');
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not available';
    try {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleString();
    } catch (e) {
      return 'Invalid date';
    }
  };

  const getRoleBadgeClass = (role) => {
    switch (role) {
      case 'admin':
        return 'bg-purple-100 text-purple-800';
      case 'blandford':
        return 'bg-blue-100 text-blue-800';
      case 'coolmore':
        return 'bg-green-100 text-green-800';
      case 'friend':
        return 'bg-pink-100 text-pink-800';
      case 'trial':
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Sort the filtered users
  const sortedUsers = stableSort(filteredUsers, getComparator(order, orderBy));

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Manage Users
          </CardTitle>
        </CardHeader>
        <CardContent>
          {actionResult.message && (
            <div className={`mb-4 p-3 rounded-md ${actionResult.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {actionResult.message}
            </div>
          )}

          {error && (
            <div className="text-red-600 text-sm mb-4">{error}</div>
          )}
          
          <div className="bg-blue-50 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm"><strong>Tip:</strong> Click on a user row to edit their role. Only @jhjwconsulting.com emails can be made admins.</p>
            {currentUserEmail === 'sam.dolbear@jhjwconsulting.com' && (
              <p className="text-sm mt-1"><strong>Admin:</strong> You have special privileges to remove and demote admin users.</p>
            )}
          </div>

          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by name, username, email or role..."
              className="w-full p-2 border border-gray-300 rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {loading && <div className="text-center py-4">Loading...</div>}

          {!loading && filteredUsers.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              {searchTerm 
                ? "No users match your search" 
                : "No users found"}
            </div>
          )}

          {!loading && filteredUsers.length > 0 && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('name')}
                    >
                      Name {orderBy === 'name' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('username')}
                    >
                      Username {orderBy === 'username' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('email')}
                    >
                      Email {orderBy === 'email' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('role')}
                    >
                      Role {orderBy === 'role' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Created {orderBy === 'createdAt' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {sortedUsers.map((user) => (
                    <tr 
                      key={user._id} 
                      className={canUserChangeRole(user) ? "cursor-pointer hover:bg-gray-50" : ""}
                      onClick={() => canUserChangeRole(user) && handleEditRole(user._id, user.role)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{user.name || '-'}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{user.username}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{user.email || 'Not available'}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {editingUserId === user._id ? (
                          <div className="flex items-center space-x-2" onClick={(e) => e.stopPropagation()}>
                            <select
                              value={selectedRole}
                              onChange={handleRoleChange}
                              className="border border-gray-300 rounded px-2 py-1 text-sm"
                            >
                              <option value="trial">trial</option>
                              <option value="friend">friend</option>
                              <option value="blandford">blandford</option>
                              <option value="coolmore">coolmore</option>
                              {isAdminRoleAllowed(user.email) && <option value="admin">admin</option>}
                            </select>
                            <button 
                              className="text-green-600 hover:text-green-900 text-xs"
                              onClick={() => handleSaveRole(user._id, user.email)}
                            >
                              Save
                            </button>
                            <button 
                              className="text-gray-600 hover:text-gray-900 text-xs"
                              onClick={handleCancelEdit}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getRoleBadgeClass(user.role || 'trial')}`}>
                            {user.role || 'trial'}
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(user.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={() => handleRemoveUser(user._id, user.username || user._id)}
                          className="text-red-600 hover:text-red-900"
                          disabled={(user.role || '').toLowerCase() === 'admin' && currentUserEmail !== 'sam.dolbear@jhjwconsulting.com'}
                          title={(user.role || '').toLowerCase() === 'admin' && currentUserEmail !== 'sam.dolbear@jhjwconsulting.com' ? "Admin users cannot be removed" : ""}
                        >
                          {(user.role || '').toLowerCase() === 'admin' && currentUserEmail !== 'sam.dolbear@jhjwconsulting.com' ? 'Protected' : 'Remove'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-6">
            <button
              onClick={fetchUsers}
              disabled={loading}
              className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors disabled:bg-gray-100 disabled:text-gray-400"
            >
              Refresh
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}