import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import goingScale from './collatedGoingAdjustmentScale.png'
import GoingAdjustmentsCourse from './components/going_table.js'
import useToken from '../App/useToken'
import fetchWithAuth from '../Utils/FetchWithAuth'
import Footer from '../Footer/Footer'
import LeaderboardHeader from '../LeaderBoards/components/LeaderboardHeader'

const GoingAdjustments = () => {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(null)
  const [possDates, setPossDates] = useState([])
  const [courseVectorIRE_F, setCourseVectorIRE_F] = useState([])
  const [courseVectorGB_F, setCourseVectorGB_F] = useState([])
  const [courseVectorIRE_J, setCourseVectorIRE_J] = useState([]);
  const [courseVectorGB_J, setCourseVectorGB_J] = useState([]);
  const [raceDate, setRaceDate] = useState(new Date().toISOString().split('T')[0]);
  const { token } = useToken()

  const loadRaces = (e) => {
    e.preventDefault()
  }

  const fetchDates = async () => {
    try {
      const [IRE_F, GB_F, IRE_J, GB_J] = await Promise.all([
        fetchWithAuth('dates', { countryCode: 'IRE_F' }, token),
        fetchWithAuth('dates', { countryCode: 'GB_F' }, token),
        fetchWithAuth('dates', { countryCode: 'IRE_J' }, token),
        fetchWithAuth('dates', { countryCode: 'GB_J' }, token)
      ]);
  
      const combinedDates = [...IRE_F, ...GB_F, ...IRE_J, ...GB_J];
      const uniqueDates = Array.from(new Set(combinedDates)).sort().reverse();
      setPossDates(uniqueDates);
    } catch (error) {
      setError(error);
    }
  };

  // Run only once
  useEffect(() => {
    fetchDates()
  }, [])

  useEffect(() => {
    if (raceDate) {
      setCourseVectorIRE_F([])
      fetchWithAuth('courses', {raceDate: raceDate, countryCode: 'IRE_F'}, token)
        .then((result) => setCourseVectorIRE_F(result))
        .catch((error) => setError(error))
        .finally(() => setIsLoaded(true))

      setCourseVectorGB_F([])
      fetchWithAuth('courses', {raceDate: raceDate, countryCode: 'GB_F'}, token)
        .then((result) => setCourseVectorGB_F(result))
        .catch((error) => setError(error))
        .finally(() => setIsLoaded(true))
      
      setCourseVectorIRE_J([]);
      fetchWithAuth('courses', { raceDate, countryCode: 'IRE_J' }, token)
        .then((result) => setCourseVectorIRE_J(result))
        .catch((error) => setError(error))
        .finally(() => setIsLoaded(true));
  
      setCourseVectorGB_J([]);
      fetchWithAuth('courses', { raceDate, countryCode: 'GB_J' }, token)
        .then((result) => setCourseVectorGB_J(result))
        .catch((error) => setError(error))
        .finally(() => setIsLoaded(true));
    }
  }, [raceDate])

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white py-6 sm:py-12">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <LeaderboardHeader
                title="Race by Race Live Going Assessments"
                subtitle={`Scales to interpret going index. \nTop: Turf Flat \nMiddle: AW Flat \nBottom: National Hunt`}
              />
              <img src={goingScale} alt="Going Adjustment Scale" className='object-center'/>
            </div>
            
            <div className="mt-6 mb-6 mx-auto w-56 text-center">
              <form onChange={loadRaces} className='smartParagraph'>
                <label htmlFor="racedate" className="text-8xl font-bold tracking-tight text-gray-900 sm:text-2xl">Select Date:</label>
                <DatePicker
                  selected={new Date(raceDate)}
                  onChange={(date) => setRaceDate(date.toISOString().split('T')[0])}
                  dateFormat="dd-MMM-yyyy"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholderText="Select Date"
                />
              </form>
            </div>
            
            <div>
              {courseVectorIRE_F.map((name) => (
                <GoingAdjustmentsCourse key={name} text={{ courseName: name, countryCode: 'IRE_F', date: raceDate, type: 'flat' }} />
              ))}
              {courseVectorGB_F.map((name) => (
                <GoingAdjustmentsCourse key={name} text={{ courseName: name, countryCode: 'GB_F', date: raceDate, type: 'flat' }} />
              ))}
              {courseVectorIRE_J.map((name) => (
                <GoingAdjustmentsCourse key={name} text={{ courseName: name, countryCode: 'IRE_J', date: raceDate, type: 'jump' }} />
              ))}
              {courseVectorGB_J.map((name) => (
                <GoingAdjustmentsCourse key={name} text={{ courseName: name, countryCode: 'GB_J', date: raceDate, type: 'jump' }} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default GoingAdjustments
