import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Tooltip from '@mui/material/Tooltip';
import useToken from '../../App/useToken'
import getRole from '../../Utils/getRole'
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../../Utils/EnhancedTableHead';
import { getComparator, stableSort } from '../../Utils/SortArray'
import capitalizeFirstLetter from '../../Utils/Capitalise'
import fetchWithAuth from '../../Utils/FetchWithAuth'
import useViewportWidth from '../../Utils/useViewportWidth';
import { generateHorseUrl, generateHorseSilk } from '../../Utils/RaceUtils';


function getHeadCells(role) {
  const first = 
  [
    {
      id: 'finishPosition',
      numeric: true,
      label: 'Fin Pos.',
      fixed: true,
    },
    {
      id: 'raceCard',
      numeric: false,
      label: 'Race Card',
      fixed: true, 
      align: 'left',
      sticky: true
    },
    {
      id: 'horseName',
      numeric: false,
      label: 'Horse Name',
      fixed: true,
      align: 'left',
    },
    {
      id: 'ownerName',
      numeric: false,
      label: 'Owner',
      align: 'left',
    },
    {
      id: 'trainerName',
      numeric: false,
      label: 'Trainer',
      align: 'left',
    },
    {
      id: 'OSEX',
      numeric: false,
      label: 'Sex',
      align: 'right',
    },
    {
      id: 'OAGE',
      numeric: true,
      label: 'Age',
    },
    // {
    //   id: 'OSADDLE',
    //   numeric: true,
    //   label: 'Saddle',
    // },
    // {
    //   id: 'ODRAW',
    //   numeric: true,
    //   label: 'Draw',
    // },
    {
      id: 'OWEIGHT',
      numeric: true,
      label: 'Weight',
    },
    
  ]
  const second = [
    {
      id: 'OALLOW',
      numeric: true,
      label: 'Allowance',
    },
    {
      id: 'OR',
      numeric: true,
      label: 'ORpre',
    }
  ]
  const third = [
    {
      id: 'ORpost',
      numeric: true,
      label: 'ORpost',
    }
  ]
  const fourth = [
    {
      id: 'RPRpost',
      numeric: true,
      label: 'RPRpost',
    },
    {
      id: 'TFpost',
      numeric: true,
      label: 'TFpost',
    },
    {
      id: 'TFtfigPost',
      numeric: true,
      label: 'TFtfigPost',
    },
    
  ]
  const fifth = [
    {
      id: 'SmartMasterWgtAdj',
      numeric: true,
      label: 'Smart Master WA',
    }
  ]
  const sixth = [
    {
      id: 'SmartMasterTimePre',
      numeric: true,
      label: 'Smart Master Time Pre',
    }
  ]
  const seventh = [
    {
      id: 'SmartForm',
      numeric: true,
      label: 'Smart Form',
    },
    {
      id: 'SmartTime',
      numeric: true,
      label: 'Smart Time',
    },
  ]
  const eighth = [
    {
      id: 'SmartSecTime',
      numeric: true,
      label: 'Smart Sec Time',
    }
  ]

  const ninth = [
    {
      id: 'SmartRating',
      numeric: true,
      label: 'Smart Rating',
    }
  ]

  const tenth = [
    {
      id: 'Source',
      numeric: false,
      label: 'SecSource ',
      fixed: false,
      align: 'right',
    },
    {
      id: 'RankRnrs',
      numeric: true,
      label: 'RankRnrs',
      fixed: false,
    },
    {
      id: 'timeRnk',
      numeric: true,
      label: 'TimeRank',
      fixed: false,
    },
    {
      id: 'secRnk',
      numeric: true,
      label: 'SecRank',
      fixed: false,
    },
    {
      id: 'cadenceMax',
      numeric: true,
      label: 'CadenceMax',
      fixed: false,
    },
    {
      id: 'cadenceMaxRnk',
      numeric: true,
      label: 'CadenceMaxRank',
      fixed: false,
    },
    {
      id: 'stdLthMax',
      numeric: true,
      label: 'StdLthMax',
      fixed: false,
    },
    {
      id: 'stdLthRnk',
      numeric: true,
      label: 'StdLthRank',
      fixed: false,
    },
  ]

  if (role === "admin" || role === "blandford") {
    return first.concat( second, fifth, sixth, seventh, eighth, ninth,  third, fourth, tenth)
  } 

  return first.concat( second, fifth, seventh, ninth, fourth) 

}

function CountryRacecard ({ text }) {
  // //API CALL
  const [error, setError] = useState(null)
  const [raceLoaded, setRaceLoaded] = useState(false)
  const [possDates, setPossDates] = useState([])
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [possCourses, setPossCourses] = useState([])
  const [possTimes, setPossTimes] = useState([])
  const [horses, setHorses] = useState([])
  const [raceInfo, setRaceInfo] = useState([])
  const [smartContent, setSmartContent] = useState([])

  const [meetingRaces, setMeetingRaces] = useState([]);
  const [raceDetails, setRaceDetails] = useState({});
  const [raceDate, setRaceDate] = useState(new Date().toISOString().split('T')[0]);
  const [raceDataSource, setRaceDataSource] = useState('');

  const [course, setCourse] = useState(null)
  const [time, setTime] = useState(null)

  const [order, setOrder] = useState('desc'); 
  const [orderBy, setOrderBy] = useState('SmartMasterWgtAdj');
  const [sortedRows, setSortedRows] = useState([]);
  const [allTablesSortState, setAllTablesSortState] = useState({});


  const { token } = useToken()
  const role = getRole()

  const screenWidth = useViewportWidth();
  const isMobileView = screenWidth < 1000;

  const country = text.countryCode.split('_')[0];

  const loadRaces = (e) => {
    e.preventDefault()
  }

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleAllTablesRequestSort = (raceIdentifier, property) => {
    const isAsc = allTablesSortState[raceIdentifier]?.orderBy === property && allTablesSortState[raceIdentifier]?.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
  
    setAllTablesSortState({
      ...allTablesSortState,
      [raceIdentifier]: {
        order: newOrder,
        orderBy: property,
      },
    });
  };
  

  useEffect(() => {
    fetchWithAuth('dates', {countryCode: text.countryCode}, token)
      .then((result) => setPossDates(result.reverse()))
      .catch((error) => setError(error))
  }, [])

  useEffect(() => {
    if (raceDate) {
      fetchWithAuth('courses', {countryCode: text.countryCode, raceDate: raceDate}, token)
        .then((result) => setPossCourses(result))
        .catch((error) => setError(error));
    } else {
      setPossCourses([]);
    }
  }, [raceDate, text.countryCode, token]);

  useEffect(() => {
    if (course && raceDate) {
      fetchWithAuth('racesByTime', {countryCode: text.countryCode, course: course, raceDate: raceDate}, token)
        .then((result) => setPossTimes(result))
        .catch((error) => setError(error));
    } else {
      setPossTimes([]);
    }
  }, [course, raceDate, text.countryCode, token]);
  
  // For All races option
  useEffect(() => {
    if (course && raceDate && time === 'All') {
      const adjustedCountryCode = text.countryCode.substring(0, text.countryCode.length - 2);

      let adjustedCourseName;

      if (course === "NEWMARKETROWLEY") {
        adjustedCourseName = course.toUpperCase().substring(0, 4) + 'R';
      } else {
        adjustedCourseName = course.toUpperCase().substring(0, 5);
      }
      
      const smartMeetID = `${adjustedCountryCode}_${adjustedCourseName}_${raceDate}`;
  
      fetchWithAuth('meetingInfo', { smartMeetID: smartMeetID, countryCode: text.countryCode }, token)
        .then(async (meetingRaces) => {

          const detailedRacesPromises = meetingRaces.map(async (race) => {
            
            const raceDetailsPromise = fetchWithAuth('raceLevelInfo', {
              countryCode: text.countryCode,
              course: course,
              raceTime: race.raceTime,
              raceDate: raceDate
            }, token);
  
            const horseDetailsPromise = fetchWithAuth('horseLevelInfo', {
              countryCode: text.countryCode,
              course: course,
              raceTime: race.raceTime,
              raceDate: raceDate
            }, token);
  
            const [raceDetails, horseDetails] = await Promise.all([raceDetailsPromise, horseDetailsPromise]);

            // Log the new race level information
            // console.log('Race Level Info:', {
            //   smartRaceID: raceDetails[0].smartRaceID,
            //   dataSource: raceDetails[0].dataSource 
            // });

            // Log each horse's new information
            // horseDetails[0].horses.forEach(horse => {
            //   console.log('Horse Info:', {
            //     silkCode: horse.silkCode, 
            //     horseID: horse.horseID
            //   });
            // });
  
            let initialSorting = {
              order: 'desc', 
              orderBy: 'SmartMasterWgtAdj', 
            };
  
            if (raceDetails[0].PostRaceRated === 1) {
              initialSorting = {
                order: 'asc',
                orderBy: 'finishPosition',
              };
            }
  
            return { 
              ...race, 
              raceDetails: raceDetails[0], 
              horseDetails: horseDetails[0].horses,
              raceDate: raceDate, 
              course: course,     
              country: text.countryCode.split('_')[0], 
              initialSorting, 
            };
          });
  
          const detailedRaces = await Promise.all(detailedRacesPromises);
          
          setMeetingRaces(detailedRaces);
        })
        .catch((error) => setError(error));
    }
  }, [course, raceDate, time, text.countryCode, token]);

  // For individual races
  useEffect(() => {
    if (time) {
      // Fetching horse level info
      fetchWithAuth('horseLevelInfo', {countryCode: text.countryCode, course: course, raceTime: time, raceDate: raceDate}, token)
        .then((result) => {
          // Console logging for each horse's silkCode and horseID
          result[0].horses.forEach(horse => {
            // console.log('Horse Level Info:', {
            //   silkCode: horse.silkCode,
            //   horseID: horse.horseID
            // });
          });
          setHorses(result[0].horses);
        })
        .catch((error) => setError(error));
  
      // Fetching race level info
      fetchWithAuth('raceLevelInfo', {countryCode: text.countryCode, course: course, raceTime: time, raceDate: raceDate}, token)
        .then((result) => {
          const raceInfo = result[0];
          // Console logging for the dataSource for the race
          // console.log('Race Level Info:', {
          //   smartRaceID: raceInfo.smartRaceID,
          //   dataSource: raceInfo.dataSource // Assuming dataSource is now being fetched correctly
          // });

          setRaceDataSource(raceInfo.dataSource);
  
          setRaceInfo([
            { id: 1, name: 'Smart Race ID', value: raceInfo.smartRaceID },
            { id: 2, name: 'Official Going', value: raceInfo.OGOING },
            { id: 3, name: 'Distance (f)', value: `${isNaN(Number(raceInfo.distExact).toFixed(1)) ? '' : Number(raceInfo.distExact).toFixed(1)}` },
            { id: 4, name: 'Expected Strength', value: `${isNaN(Number(raceInfo.XStrength).toFixed(1)) ? '' : Number(raceInfo.XStrength).toFixed(1)}` },
            { id: 5, name: 'Race Title', value: raceInfo.raceTitle },
            { id: 6, name: 'Age', value: raceInfo.ageConditions },
            { id: 7, name: 'Gender', value: raceInfo.genderConditions },
            { id: 8, name: 'Handicap Level', value: raceInfo.hcapConditions },
            // { id: 6, name: 'Data Source', value: raceInfo.dataSource || 'N/A' }
          ]);
  
          // Existing logic to determine orderBy and order
          if (raceInfo.PostRaceRated === 1) { 
            setOrderBy('finishPosition');
            setOrder('asc');
          } else {
            setOrderBy('SmartMasterWgtAdj');
          }
        })
        .catch((error) => setError(error))
        .finally(() => setRaceLoaded(true));
    }
  }, [time, course, text.countryCode, raceDate, token]); 

  // Bodge to remove SmartForm and SmartRating for DNF horses
  useEffect(() => {
    if (horses && horses.length > 0) {
      const processedHorses = horses.map((horse) => {
        if (horse.SmartTime === null || horse.SmartTime === 'NaN' || horse.SmartTime === undefined) {
          const { SmartRating, SmartForm, ...remainingHorseData } = horse; 
          return remainingHorseData; 
        }
        return horse; 
      });
      setHorses(processedHorses); 
    }
  }, [horses]);
  

  useEffect(() => {
    if (raceLoaded) {
      fetchWithAuth('SmartRaceContent', {collection: 'SmartOutings', smartRaceID: raceInfo[0].value}, token)
        .then((result) => setSmartContent(result))
        .catch((error) => setError(error))
    }
  }, [raceInfo, horses])
  
  useEffect(() => {
    if (horses) {
      const [emptyValuesHorses, nonEmptyValuesHorses] = horses.reduce(([empty, nonEmpty], horse) => {
        const isEmpty = !horse[orderBy] && horse[orderBy] !== 0; // Adjust based on what constitutes 'empty'
        return isEmpty ? [[...empty, horse], nonEmpty] : [empty, [...nonEmpty, horse]];
      }, [[], []]);

      const sortedNonEmptyHorses = stableSort(nonEmptyValuesHorses, getComparator(order, orderBy));

      const finalSortedHorses = [...sortedNonEmptyHorses, ...emptyValuesHorses];
      setSortedRows(finalSortedHorses);
    }
  }, [order, orderBy, horses]);

  return (
        <div>
          <div className="mx-auto w-56 text-center">
            <form onChange={loadRaces} className="mt-6">
              <label htmlFor="racedate" className="block text-sm font-medium leading-6 text-gray-900">Select Date:</label>
              <DatePicker
                selected={new Date(raceDate)}
                onChange={(date) => {
                  setRaceDate(date.toISOString().split('T')[0]); // Update the race date
                  setTime(""); // Reset the time to the initial state (forcing "Select Race Time")
                  // Optionally reset other states or perform additional actions as needed
                }} 
                dateFormat="dd-MMM-yyyy"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label htmlFor="course" className='mt-4 block text-sm font-medium leading-6 text-gray-900'>Choose a Course:</label>
              <select
                id="course"
                name="course"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => setCourse(e.target.value)}
              >
                <option value="">Select Course</option>
                {possCourses.map((course) => (
                  <option key={course} value={course}>
                    {course}
                  </option>
                ))} 
              </select>

              <label htmlFor="time" className='mt-4 block text-sm font-medium leading-6 text-gray-900'>Choose a Race Time:</label>
              <select
                id="time"
                name="time"
                value={time} 
                onChange={(e) => setTime(e.target.value)} 
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Race Time</option> 
                {possTimes.map((timeOption) => (
                  <option key={timeOption} value={timeOption}>
                    {timeOption}
                  </option>
                ))}
                {course && <option value="All">All</option>}
              </select>

            </form>
          </div>
        {time !== 'All' && (
          <div className="bg-white py-12 sm:py-12">
            <div className="mx-auto px-6 lg:max-w-none">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Smart Content 
                </h2>
              </div>
              <ul role="list" className="divide-y divide-gray-100">
                {smartContent.map((comment) => (
                  <li key={comment.id} className="flex gap-x-4 py-5">
                    <div className="flex-auto">
                      <div className="flex items-baseline justify-between gap-x-4">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{`${comment.preOrPost} - ${capitalizeFirstLetter(comment.contentLevel)} Level`}</p>
                        <p className="flex-none text-xs text-gray-600">
                          <p>{comment.contentType}</p>
                        </p>
                      </div>
                      <p className="mt-1 text-sm leading-6 text-gray-600">{comment.content}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      {time !== 'All' && (
        <div className="bg-white py-12 sm:py-12">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Race Information
                </h2>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                  Information available at the horse level.
                </p>
              </div>
              <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              {raceInfo.map((val, index) => {
                // Check the 'Handicaps' value only once before mapping begins
                const handicapsValue = raceInfo.find(info => info.id === 8)?.value;
                const isHandicapsNull = handicapsValue === null;

                // Condition to skip rendering if the id is 8 and value is null
                if (val.id === 8 && isHandicapsNull) {
                  return null;
                }

                // Determine column span
                let colSpan = '';
                if (index === 4) {
                  colSpan = 'lg:col-span-4'; // Race title spans all columns
                } else if (isHandicapsNull && (val.id === 6 || val.id === 7)) {
                  colSpan = 'lg:col-span-2'; // Double span for id 6 and 7 if Handicaps is null
                } else if (!isHandicapsNull && (val.id === 6 || val.id === 7 || val.id === 8)) {
                  colSpan = 'lg:col-span-1'; // Normal single span when Handicaps is not null
                }

                return (
                  <div key={val.id} className={`flex flex-col bg-gray-400/5 p-8 ${colSpan}`}>
                    <dt className="text-sm font-semibold leading-6 text-gray-600">{val.name}</dt>
                    <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">{val.value}</dd>
                  </div>
                );
              })}
              </dl>
            </div>
          </div>
        </div>
      )}
      {time !== 'All' && (
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Race Card</h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            WA - Weight Adjusted <br />
            OR - Official Rating <br />
          </p>
        </div>
      )}  

        {time !== 'All' ? ( 
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer 
                sx=
                {{ 
                  // maxHeight: 600 
                }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  headCells={getHeadCells(role)}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortedRows.map((horse) => {                    
                  return(
                    <TableRow 
                      hover
                      key={horse.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell 
                        align='right'
                        sx={{
                          "background": "#f5f5f5", 
                        }}
                      >
                        {horse.finishPosition}
                      </TableCell>
                      <TableCell 
                        sx={{
                          ...(isMobileView ? {
                            left: 0,
                            background: "#f5f5f5",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                          } : { 
                            position: "sticky",
                            left: 0,
                            zIndex: 800,
                            background: "#f5f5f5",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                          })
                        }}
                      >
                        <div className="mb-2 mr-2" style={{ fontWeight: 'bold' }}>
                          {typeof horse.ODRAW !== 'undefined' && horse.ODRAW !== null ? `${horse.OSADDLE} (${horse.ODRAW})` : `${horse.OSADDLE}`}
                        </div>

                        <div>
                          <img 
                            src={generateHorseSilk(raceDataSource, horse.silkCode, raceDate, course, time, horse.OSADDLE, country)}
                            alt=""
                            style={{ width: '50px', height: 'auto' }}
                          />
                        </div>
                      </TableCell>
                      <TableCell 
                        sx={{
                          ...(isMobileView ? {
                            left: 0,
                            background: "#f5f5f5",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                          } : { 
                            "position": "sticky",
                            "left": "92px",
                            "zIndex": 800,
                            "background": "#f5f5f5", 
                            "wordWrap": "break-word", 
                            "fontWeight": "bold"
                          })
                        }}
                      >
                        <a
                          href={generateHorseUrl(
                            raceDataSource,
                            horse.horseName,
                            horse.horseID,
                            horse.horseSuf,
                            raceDate,
                            course,
                            time,
                            country 
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-inherit hover:text-blue-500"
                          style={{ textDecoration: 'none' }}
                        >
                          {horse.horseName.toUpperCase()}
                        </a>
                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title={horse.ownerName}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                          {horse.ownerName}
                          </div>
                        </Tooltip>
                        </TableCell>
                        <TableCell align='left'>
                        <Tooltip title={horse.trainerName}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                          {horse.trainerName}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell 
                      align='right'
                      >
                        {horse.OSEX}
                      </TableCell>
                      <TableCell 
                      align='right'
                      >
                        {horse.OAGE}
                      </TableCell>
                      {/* <TableCell 
                      align='right'
                      >
                        {horse.OSADDLE}
                      </TableCell> */}
                      {/* {!shouldHideDrawColumn && (
                        <TableCell align="right">
                          {horse.ODRAW}
                        </TableCell>
                      )} */}
                      <TableCell 
                      align='right'
                      >
                        {horse.OWEIGHT}
                      </TableCell>
                      <TableCell 
                      align='right'
                      >
                        {horse.OALLOW}
                      </TableCell>
                      <TableCell 
                      align='right'
                      >
                        {horse.OR}
                      </TableCell>
                      <TableCell 
                      align='right'
                      >
                        {horse.SmartMasterWgtAdj === 'NaN' || horse.SmartMasterWgtAdj == null ? '' : Number(horse.SmartMasterWgtAdj).toFixed(1)}
                      </TableCell>
                      {role === 'admin' || role === 'blandford'?
                      <TableCell 
                      align='right'
                      >
                        {horse.SmartMasterTimePre === 'NaN' || horse.SmartMasterTimePre == null ? '' : Number(horse.SmartMasterTimePre).toFixed(1)}
                      </TableCell> 
                      : null }
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.SmartForm === 'NaN' || horse.SmartForm == null ? '' : Number(horse.SmartForm).toFixed(1)}
                      </TableCell>
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.SmartTime === 'NaN' || horse.SmartTime == null ? '' : Number(horse.SmartTime).toFixed(1)}
                      </TableCell>
                      {role === 'admin' || role === 'blandford'?
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.SmartSecTime === 'NaN' || horse.SmartSecTime == null ? '' : Number(horse.SmartSecTime).toFixed(1)}
                      </TableCell>
                      : null }
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.SmartRating === 'NaN' || horse.SmartRating == null ? '' : Number(horse.SmartRating).toFixed(1)}
                      </TableCell>
                      {role === 'admin' || role === 'blandford'?
                        <TableCell 
                        align='right'
                        style={{ fontWeight: 'bold' }}
                        >
                          {horse.ORpost === 'NaN' || horse.ORpost == null ? '' : Number(horse.ORpost).toFixed(1)}
                      </TableCell>
                      : null }
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.RPRpost === 'NaN' || horse.RPRpost == null ? '' : Number(horse.RPRpost).toFixed(1)}
                      </TableCell>
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.TFpost === 'NaN' || horse.TFpost == null ? '' : Number(horse.TFpost).toFixed(1)}
                      </TableCell>
                      <TableCell 
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.TFtfigPost === 'NaN' || horse.TFtfigPost == null ? '' : Number(horse.TFtfigPost).toFixed(1)}
                      </TableCell> 

                     {/* Third party adder variables */}
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.Source !== 'NaN' && horse.Source}
                      </TableCell>  
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.RankRnrs === 'NaN' || horse.RankRnrs == null ? '' : Number(horse.RankRnrs).toFixed(1)}
                      </TableCell>                                         
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.timeRnk === 'NaN' || horse.timeRnk == null ? '' : Number(horse.timeRnk).toFixed(1)}
                      </TableCell>                       
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.secRnk === 'NaN' || horse.secRnk == null ? '' : Number(horse.secRnk).toFixed(1)}
                      </TableCell>                                       
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.cadenceMax === 'NaN' || horse.cadenceMax == null ? '' : Number(horse.cadenceMax).toFixed(1)}
                      </TableCell>                      
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.cadenceMaxRnk === 'NaN' || horse.cadenceMaxRnk == null ? '' : Number(horse.cadenceMaxRnk).toFixed(1)}
                      </TableCell>
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.stdLthMax === 'NaN' || horse.stdLthMax == null ? '' : Number(horse.stdLthMax).toFixed(1)}
                      </TableCell>
                      <TableCell
                      align='right'
                      style={{ fontWeight: 'bold' }}
                      >
                        {horse.stdLthRnk === 'NaN' || horse.stdLthRnk == null ? '' : Number(horse.stdLthRnk).toFixed(1)}
                      </TableCell>
                        
                  </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <div>
            {meetingRaces.map((race, index) => {
              const raceIdentifier = race.raceTime;
              const raceDataSource = race.raceDetails.dataSource;
              const ageConditions = race.raceDetails.ageConditions || 'N/A';
              const genderConditions = race.raceDetails.genderConditions || 'N/A';
              const hcapConditions = race.raceDetails.hcapConditions || 'N/A';
              const currentSortState = allTablesSortState[raceIdentifier] || race.initialSorting || {
                order: 'asc',
                orderBy: 'SmartMasterWgtAdj',
              };

              // Extract race-specific variables
              const raceDate = race.raceDate || raceDetails.raceDate || 'defaultDate';
              const course = race.course || raceDetails.course || 'defaultCourse';
              const time = race.raceTime || raceDetails.raceTime || 'defaultTime';
              const country = race.country || raceDetails.country || 'GB';
              
              // Replicate the useEffect for empty values 
              const [emptyValuesHorses, nonEmptyValuesHorses] = race.horseDetails.reduce(([empty, nonEmpty], horse) => {
                const value = horse[currentSortState.orderBy];
                const isEmpty = value === '' || value === null || value === undefined;
                return isEmpty ? [[...empty, horse], nonEmpty] : [empty, [...nonEmpty, horse]];
              }, [[], []]);
            
              const comparator = getComparator(currentSortState.order, currentSortState.orderBy);
              const sortedNonEmptyHorses = stableSort(nonEmptyValuesHorses, comparator);
            
              const finalSortedHorses = [...sortedNonEmptyHorses, ...emptyValuesHorses];

              return (
                <div key={index}>
                <h3 className="text-lg font-semibold py-2 px-4 bg-gray-200 mt-20">{course} - {race.raceTime} - {race.distExact.toFixed(1)}f - {race.OGOING} - {race.raceTitle} - {ageConditions} - {genderConditions} - {hcapConditions}</h3> 
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '16px' }}>
                  <TableContainer 
                    sx=
                      {{ 
                        // maxHeight: 600 
                      }}
                    >
                    <Table stickyHeader aria-label="sticky table">
                      <EnhancedTableHead
                        headCells={getHeadCells(role)}
                        order={currentSortState.order}
                        orderBy={currentSortState.orderBy}
                        onRequestSort={(event, property) => handleAllTablesRequestSort(raceIdentifier, property)}
                      />
                      <TableBody>
                        {finalSortedHorses.map((horse) => (   
                          <TableRow 
                          hover
                          key={horse.id}
                          sx={{ cursor: 'pointer' }}
                          >
                            <TableCell 
                            align='right'
                            sx={{
                              "background": "#f5f5f5", 
                            }}
                            >
                              {horse.finishPosition}
                            </TableCell>
                            <TableCell 
                              sx={{
                                ...(isMobileView ? {
                                  left: 0,
                                  background: "#f5f5f5",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                } : { 
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 800,
                                  background: "#f5f5f5",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                })
                              }}
                            >
                              <div className="mb-2 mr-2" style={{ fontWeight: 'bold' }}>
                                {typeof horse.ODRAW !== 'undefined' && horse.ODRAW !== null ? `${horse.OSADDLE} (${horse.ODRAW})` : `${horse.OSADDLE}`}
                              </div>

                              <div>
                                <img 
                                  src={generateHorseSilk(raceDataSource, horse.silkCode, raceDate, course, time, horse.OSADDLE, country)}
                                  alt=""
                                  style={{ width: '50px', height: 'auto' }}
                                />
                              </div>
                            </TableCell>
                            <TableCell 
                              sx={{
                                ...(isMobileView ? {
                                  left: 0,
                                  background: "#f5f5f5",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                } : { 
                                  "position": "sticky",
                                  "left": "92px",
                                  "zIndex": 800,
                                  "background": "#f5f5f5", 
                                  "wordWrap": "break-word", 
                                  "fontWeight": "bold"
                                })
                              }}
                            >
                              <a
                                href={generateHorseUrl(
                                  raceDataSource,
                                  horse.horseName,
                                  horse.horseID,
                                  horse.horseSuf,
                                  raceDate,
                                  course,
                                  time,
                                  country 
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-inherit hover:text-blue-500"
                                style={{ textDecoration: 'none' }}
                              >
                                {horse.horseName.toUpperCase()}
                              </a>
                            </TableCell>
                            <TableCell align='left'>
                              <Tooltip title={horse.ownerName}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                                {horse.ownerName}
                                </div>
                              </Tooltip>
                              </TableCell>
                              <TableCell align='left'>
                              <Tooltip title={horse.trainerName}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75px' }}>
                                {horse.trainerName}
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell 
                            align='right'
                            >
                              {horse.OSEX}
                            </TableCell>
                            <TableCell 
                            align='right'
                            >
                              {horse.OAGE}
                            </TableCell>
                            {/* <TableCell 
                            align='right'
                            >
                              {horse.OSADDLE}
                            </TableCell>
                            {!shouldHideDrawColumn && (
                              <TableCell align="right">
                                {horse.ODRAW}
                              </TableCell>
                            )} */}
                            <TableCell 
                            align='right'
                            >
                              {horse.OWEIGHT}
                            </TableCell>
                            <TableCell 
                            align='right'
                            >
                              {horse.OALLOW}
                            </TableCell>
                            <TableCell 
                            align='right'
                            >
                              {horse.OR}
                            </TableCell>
                            <TableCell 
                              align='right'
                            >
                              {horse.SmartMasterWgtAdj === 'NaN' || horse.SmartMasterWgtAdj == null ? '' : Number(horse.SmartMasterWgtAdj).toFixed(1)}
                            </TableCell>
                            {role === 'admin' || role === 'blandford' ?
                              <TableCell 
                                align='right'
                              >
                                {horse.SmartMasterTimePre === 'NaN' || horse.SmartMasterTimePre == null ? '' : Number(horse.SmartMasterTimePre).toFixed(1)}
                              </TableCell> 
                              : null }
                            <TableCell 
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.SmartForm === 'NaN' || horse.SmartForm == null ? '' : Number(horse.SmartForm).toFixed(1)}
                            </TableCell>
                            <TableCell 
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.SmartTime === 'NaN' || horse.SmartTime == null ? '' : Number(horse.SmartTime).toFixed(1)}
                            </TableCell>
                            {role === 'admin' || role === 'blandford' ?
                              <TableCell 
                                align='right'
                                style={{ fontWeight: 'bold' }}
                              >
                                {horse.SmartSecTime === 'NaN' || horse.SmartSecTime == null ? '' : Number(horse.SmartSecTime).toFixed(1)}
                              </TableCell>
                              : null }
                            <TableCell 
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.SmartRating === 'NaN' || horse.SmartRating == null ? '' : Number(horse.SmartRating).toFixed(1)}
                            </TableCell>
                            {role === 'admin' || role === 'blandford'?
                              <TableCell 
                              align='right'
                              style={{ fontWeight: 'bold' }}
                              >
                                {horse.ORpost === 'NaN' || horse.ORpost == null ? '' : Number(horse.ORpost).toFixed(1)}
                            </TableCell>
                            : null }
                            <TableCell 
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.RPRpost === 'NaN' || horse.RPRpost == null ? '' : Number(horse.RPRpost).toFixed(1)}
                            </TableCell>
                            <TableCell 
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.TFpost === 'NaN' || horse.TFpost == null ? '' : Number(horse.TFpost).toFixed(1)}
                            </TableCell>
                            <TableCell 
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.TFtfigPost === 'NaN' || horse.TFtfigPost == null ? '' : Number(horse.TFtfigPost).toFixed(1)}
                            </TableCell> 

                          {/* Third party adder variables */}
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.Source !== 'NaN' && horse.Source}
                            </TableCell>  
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.RankRnrs === 'NaN' || horse.RankRnrs == null ? '' : Number(horse.RankRnrs).toFixed(1)}
                            </TableCell>                       
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.timeRnk === 'NaN' || horse.timeRnk == null ? '' : Number(horse.timeRnk).toFixed(1)}
                            </TableCell>                       
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.secRnk === 'NaN' || horse.secRnk == null ? '' : Number(horse.secRnk).toFixed(1)}
                            </TableCell>                                       
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMax === 'NaN' || horse.cadenceMax == null ? '' : Number(horse.cadenceMax).toFixed(1)}
                            </TableCell>                      
                            <TableCell
                              align='right'
                              style={{ fontWeight: 'bold' }}
                            >
                              {horse.cadenceMaxRnk === 'NaN' || horse.cadenceMaxRnk == null ? '' : Number(horse.cadenceMaxRnk).toFixed(1)}
                            </TableCell>
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthMax === 'NaN' || horse.stdLthMax == null ? '' : Number(horse.stdLthMax).toFixed(1)}
                            </TableCell>
                            <TableCell
                            align='right'
                            style={{ fontWeight: 'bold' }}
                            >
                              {horse.stdLthRnk === 'NaN' || horse.stdLthRnk == null ? '' : Number(horse.stdLthRnk).toFixed(1)}
                            </TableCell>
                              
                          </TableRow>
                        ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              )
            })}
            </div>
          )}  
  </div>
  )}

export default CountryRacecard
