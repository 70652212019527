import React, { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { Menu as MenuIcon, X as XIcon, UserCircle, Shield } from 'lucide-react';
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../images/SR.svg';
import blandford from '../../images/blandford-logo.png';
import CoolmoreLogo from '../../images/CoolmoreLogo';
import getRole from '../Utils/getRole';
import DropdownMenu from './components/DropdownMenu';
import { useTheme } from '../Theme/ThemeProvider';

function NavBar() {
  const location = useLocation();
  const history = useHistory();
  const role = getRole();
  const { theme } = useTheme();

  const getUserData = () => {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString) {
      const userData = JSON.parse(tokenString);
      return userData.user;
    }
    return null;
  };

  const user = getUserData();

  const handleLogout = async () => {
    try {
      const token = JSON.parse(sessionStorage.getItem('token')).token;
      await fetch(`${process.env.REACT_APP_BACKEND_URL}users/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      // Clear all storage to ensure clean state
      sessionStorage.removeItem('token');
      localStorage.removeItem('dev_theme_preference');
      // Force clean all localStorage items related to theming
      Object.keys(localStorage).forEach(key => {
        if (key.includes('theme')) {
          localStorage.removeItem(key);
        }
      });
      history.push('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const raceCardsMenuItems = [
    { label: 'GB_F', href: '/GB_F' },
    { label: 'GB_J', href: '/GB_J' },
    { label: 'IRE_F', href: '/IRE_F' },
    { label: 'IRE_J', href: '/IRE_J' },
    { label: 'FR_F', href: '/FR_F', showForRole: ['admin', 'friend', 'blandford', 'coolmore', 'trial'] },
  ];

  const liveGoingMenuItems = [
    { label: 'GB & IRE', href: '/GoingAdjustments' },
    { label: 'FR', href: '/GoingAdjustmentsFR', showForRole: ['admin', 'friend', 'blandford', 'coolmore', 'trial'] },
  ];

  const leaderboardMenuItems = [
    { label: 'GB & IRE Flat', href: '/LeaderBoardFlat' },
    { label: 'GB & IRE Jumps', href: '/LeaderBoardJump' },
    { label: 'FR Flat', href: '/LeaderBoardFlat_FR', showForRole: ['admin', 'friend', 'blandford', 'coolmore', 'trial'] },
  ];

  const searchMenuItems = [
    { label: 'GB & IRE', href: '/Search' },
    { label: 'FR', href: '/SearchFR', showForRole: ['admin', 'friend', 'blandford', 'coolmore', 'trial'] },
  ];

  return (
    <Disclosure as="nav" className={`relative z-50 shadow-lg ${
      theme === 'blandford' ? 'bg-[#2f2355] text-white' : 
      theme === 'coolmore' ? 'bg-[#000c40] text-white' : 
      'bg-white'
    }`}>
      {({ open }) => (
        <>
          <div className="mx-auto px-4">
            <div className="flex h-16 justify-center">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                <Disclosure.Button className={`inline-flex items-center justify-center rounded-md p-2 ${
                  theme === 'blandford' ? 'text-white hover:bg-[#3c3166] hover:text-white' : 
                  theme === 'coolmore' ? 'text-white hover:bg-[#001465] hover:text-white' :
                  'text-gray-400 hover:bg-gray-100 hover:text-gray-500'
                } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}>
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>

              <div className="hidden sm:flex items-center space-x-4">
                {theme === 'blandford' ? (
                  <img className="h-10 w-auto" src={blandford} alt="Blandford Bloodstock" />
                ) : theme === 'coolmore' ? (
                  <CoolmoreLogo className="h-10 w-auto" color="white" />
                ) : (
                  <img className="h-8 w-auto" src={logo} alt="Smart Ratings" />
                )}

                <a
                  href="/"
                  className={`inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium ${
                    theme === 'blandford'
                      ? 'text-white hover:border-white hover:text-white'
                      : theme === 'coolmore'
                        ? 'text-white hover:border-white hover:text-white'
                        : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    } focus:outline-none align-middle`}
                >
                  Home
                </a>
                <DropdownMenu
                  title="Race Cards"
                  menuItems={raceCardsMenuItems}
                  activePaths={['/GB_F', '/GB_J', '/IRE_F', '/IRE_J', '/FR_F']}
                  role={role}
                />

                {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                  <DropdownMenu
                    title="Live Going"
                    menuItems={liveGoingMenuItems}
                    activePaths={['/GoingAdjustments', '/GoingAdjustmentsFR']}
                    role={role}
                  />
                ) : (
                  <a
                    href="/GoingAdjustments"
                    className={`px-3 py-2 text-sm font-medium ${
                      location.pathname === '/GoingAdjustments'
                        ? theme === 'blandford' 
                          ? 'text-white border-b-2 border-white' 
                          : theme === 'coolmore'
                            ? 'text-white border-b-2 border-white'
                            : 'text-blue-600 border-b-2 border-blue-600'
                        : theme === 'blandford'
                          ? 'text-white hover:text-white hover:border-b-2 hover:border-white' 
                          : theme === 'coolmore'
                            ? 'text-white hover:text-white hover:border-b-2 hover:border-white'
                            : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    Live Going
                  </a>
                )}

                {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                  <DropdownMenu
                    title="Leaderboards"
                    menuItems={leaderboardMenuItems}
                    activePaths={['/LeaderBoardFlat', '/LeaderBoardFlat_FR', '/LeaderBoardJump']}
                    role={role}
                  />
                ) : (
                  <>
                    <a
                      href="/LeaderBoardFlat"
                      className={`px-3 py-2 text-sm font-medium ${
                        location.pathname === '/LeaderBoardFlat'
                          ? theme === 'blandford' 
                            ? 'text-white border-b-2 border-white' 
                            : theme === 'coolmore'
                              ? 'text-white border-b-2 border-white'
                              : 'text-blue-600 border-b-2 border-blue-600'
                          : theme === 'blandford'
                            ? 'text-white hover:text-white hover:border-b-2 hover:border-white' 
                            : theme === 'coolmore'
                              ? 'text-white hover:text-white hover:border-b-2 hover:border-white'
                              : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      Leaderboard Flat
                    </a>
                    <a
                      href="/LeaderBoardJump"
                      className={`px-3 py-2 text-sm font-medium ${
                        location.pathname === '/LeaderBoardJump'
                          ? theme === 'blandford' 
                            ? 'text-white border-b-2 border-white' 
                            : theme === 'coolmore'
                              ? 'text-white border-b-2 border-white'
                              : 'text-blue-600 border-b-2 border-blue-600'
                          : theme === 'blandford'
                            ? 'text-white hover:text-white hover:border-b-2 hover:border-white' 
                            : theme === 'coolmore'
                              ? 'text-white hover:text-white hover:border-b-2 hover:border-white'
                              : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      Leaderboard Jumps
                    </a>
                  </>
                )}

                {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                  <DropdownMenu
                    title="Search"
                    menuItems={searchMenuItems}
                    activePaths={['/Search', '/SearchFR']}
                    role={role}
                  />
                ) : (
                  <a
                    href="/Search"
                    className={`px-3 py-2 text-sm font-medium ${
                      location.pathname === '/Search'
                        ? theme === 'blandford' 
                          ? 'text-white border-b-2 border-white' 
                          : theme === 'coolmore'
                            ? 'text-white border-b-2 border-white'
                            : 'text-blue-600 border-b-2 border-blue-600'
                        : theme === 'blandford'
                          ? 'text-white hover:text-white hover:border-b-2 hover:border-white' 
                          : theme === 'coolmore'
                            ? 'text-white hover:text-white hover:border-b-2 hover:border-white'
                            : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    Search
                  </a>
                )}
                
                <a
                  href="/glossary"
                  className={`inline-flex items-center border-b-2 ${
                    location.pathname === '/glossary'
                      ? theme === 'blandford' 
                        ? 'border-white text-white' 
                        : theme === 'coolmore'
                          ? 'border-white text-white'
                          : 'border-blue-600 text-blue-600'
                      : theme === 'blandford'
                        ? 'border-transparent text-white hover:border-white hover:text-white' 
                        : theme === 'coolmore'
                          ? 'border-transparent text-white hover:border-white hover:text-white'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  } px-1 pt-1 text-base font-medium focus:outline-none align-middle`}
                >
                  Glossary
                </a>

                {role === 'admin' && (
                  <DropdownMenu
                    title={
                      <div className="flex items-center">
                        <Shield className="h-5 w-5 mr-1" />
                        Admin
                      </div>
                    }
                    menuItems={[
                      { label: 'Exception Dashboard', href: '/ExceptionDashboard' },
                      { label: 'Invite Users', href: '/invite' },
                      { label: 'Manage Invitations', href: '/manage-invitations' },
                      { label: 'Manage Users', href: '/manage-users' }
                    ]}
                    activePaths={['/ExceptionDashboard', '/invite', '/manage-invitations', '/manage-users']}
                    role={role}
                  />
                )}

                <DropdownMenu
                  title={
                    <div className="flex items-center">
                      <UserCircle className={`h-7 w-7 ${theme === 'blandford' || theme === 'coolmore' ? 'text-white' : 'text-gray-400'}`} />
                    </div>
                  }
                  menuItems={[
                    ...(user ? [{ 
                      label: (
                        <div className="py-1">
                          <div className="font-medium">Signed in as</div>
                          <div className="truncate text-gray-500">
                            {user.email || user.username}
                          </div>
                        </div>
                      ), 
                      href: "#" 
                    }] : []),
                    { label: "Account Settings", href: "/account-settings" },
                    { label: "Support", href: "/support" },
                    { 
                      label: "Sign out", 
                      href: "#",
                      onClick: (e) => {
                        e.preventDefault();
                        handleLogout();
                      }
                    }
                  ]}
                  activePaths={['/account-settings', '/support']}
                  role={role}
                />
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="sm:hidden relative">
            {/* Close button moved to top right */}
            <div className="absolute top-2 right-2">
              <Disclosure.Button className={`rounded-md p-2 ${
                theme === 'blandford' ? 'text-white hover:bg-[#3c3166] hover:text-white' : 
                theme === 'coolmore' ? 'text-white hover:bg-[#001465] hover:text-white' :
                'text-gray-400 hover:bg-gray-100 hover:text-gray-500'
              } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </Disclosure.Button>
            </div>
            
            <div className="space-y-1 pb-4 pt-2">
              <Disclosure.Button
                as="a"
                href="/"
                className={`${
                  location.pathname === '/'
                    ? 'bg-primary/10 border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                } block border-l-4 py-2 pl-3 pr-4 text-base font-medium`}
              >
                Home
              </Disclosure.Button>

              {/* Race Card options expanded as individual items */}
              <div className="border-l-4 border-transparent py-2 pl-3 pr-4">
                <div className="font-medium text-gray-700 mb-1">Race Cards</div>
                {raceCardsMenuItems.map((item, index) => (
                  (!item.showForRole || (Array.isArray(item.showForRole) ? item.showForRole.includes(role) : item.showForRole === role)) && (
                    <Disclosure.Button
                      key={index}
                      as="a"
                      href={item.href}
                      className={`block py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 ${
                        location.pathname === item.href ? 'text-primary' : ''
                      }`}
                    >
                      {item.label}
                    </Disclosure.Button>
                  )
                ))}
              </div>

              {/* Live Going options expanded for users with permission */}
              {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                <div className="border-l-4 border-transparent py-2 pl-3 pr-4">
                  <div className="font-medium text-gray-700 mb-1">Live Going</div>
                  {liveGoingMenuItems.map((item, index) => (
                    (!item.showForRole || (Array.isArray(item.showForRole) ? item.showForRole.includes(role) : item.showForRole === role)) && (
                      <Disclosure.Button
                        key={index}
                        as="a"
                        href={item.href}
                        className={`block py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 ${
                          location.pathname === item.href ? 'text-primary' : ''
                        }`}
                      >
                        {item.label}
                      </Disclosure.Button>
                    )
                  ))}
                </div>
              ) : (
                <Disclosure.Button
                  as="a"
                  href="/GoingAdjustments"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                >
                  Live Going
                </Disclosure.Button>
              )}

              {/* Leaderboard options expanded for users with permission */}
              {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                <div className="border-l-4 border-transparent py-2 pl-3 pr-4">
                  <div className="font-medium text-gray-700 mb-1">Leaderboards</div>
                  {leaderboardMenuItems.map((item, index) => (
                    (!item.showForRole || (Array.isArray(item.showForRole) ? item.showForRole.includes(role) : item.showForRole === role)) && (
                      <Disclosure.Button
                        key={index}
                        as="a"
                        href={item.href}
                        className={`block py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 ${
                          location.pathname === item.href ? 'text-primary' : ''
                        }`}
                      >
                        {item.label}
                      </Disclosure.Button>
                    )
                  ))}
                </div>
              ) : (
                <>
                  <Disclosure.Button
                    as="a"
                    href="/LeaderBoardFlat"
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  >
                    Leaderboard Flat
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/LeaderBoardJump"
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  >
                    Leaderboard Jumps
                  </Disclosure.Button>
                </>
              )}

              {/* Search options expanded for users with permission */}
              {role === 'admin' || role === 'friend' || role === 'blandford' || role === 'coolmore' || role === 'trial' ? (
                <div className="border-l-4 border-transparent py-2 pl-3 pr-4">
                  <div className="font-medium text-gray-700 mb-1">Search</div>
                  {searchMenuItems.map((item, index) => (
                    (!item.showForRole || (Array.isArray(item.showForRole) ? item.showForRole.includes(role) : item.showForRole === role)) && (
                      <Disclosure.Button
                        key={index}
                        as="a"
                        href={item.href}
                        className={`block py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 ${
                          location.pathname === item.href ? 'text-primary' : ''
                        }`}
                      >
                        {item.label}
                      </Disclosure.Button>
                    )
                  ))}
                </div>
              ) : (
                <Disclosure.Button
                  as="a"
                  href="/Search"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                >
                  Search
                </Disclosure.Button>
              )}
              
              <Disclosure.Button
                as="a"
                href="/glossary"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Glossary
              </Disclosure.Button>

              <div className="border-t border-gray-200 pt-4">
                {user && (
                  <div className="px-4 py-2 text-sm text-gray-500">
                    Signed in as {user.username}
                  </div>
                )}
                <Disclosure.Button
                  as="a"
                  href="/account-settings"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Account Settings
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/support"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Support
                </Disclosure.Button>
                {role === 'admin' && (
                  <>
                    <Disclosure.Button
                      as="a"
                      href="/ExceptionDashboard"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Exception Dashboard
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/invite"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Invite Users
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/manage-invitations"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Manage Invitations
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/manage-users"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Manage Users
                    </Disclosure.Button>
                  </>
                )}
                <Disclosure.Button
                  as="button"
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default NavBar;