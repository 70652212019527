import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import logo from '../../images/SR.svg';
import Footer from '../Footer/Footer';

const passwordCriteria = [
  { id: 'length', label: 'At least 8 characters', regex: /.{8,}/ },
  { id: 'uppercase', label: 'One uppercase letter', regex: /[A-Z]/ },
  { id: 'lowercase', label: 'One lowercase letter', regex: /[a-z]/ },
  { id: 'number', label: 'One number', regex: /\d/ },
  { id: 'special', label: 'One special character', regex: /[@$!%*?&]/ }
];

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [passwordValidation, setPasswordValidation] = useState({});
  const { token } = useParams();
  const history = useHistory();

  const validatePassword = (password) => {
    const validation = {};
    passwordCriteria.forEach(criterion => {
      validation[criterion.id] = criterion.regex.test(password);
    });
    setPasswordValidation(validation);
    return Object.values(validation).every(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    setMessage('');

    // Validate passwords match
    if (password !== confirmPassword) {
      setStatus('error');
      setMessage('Passwords do not match');
      return;
    }

    // Validate password meets all criteria
    if (!validatePassword(password)) {
      setStatus('error');
      setMessage('Password does not meet all requirements');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}users/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword: password })
      });

      const data = await response.json();

      if (response.ok) {
        setStatus('success');
        setMessage('Password updated successfully');
      } else {
        throw new Error(data.error || 'Reset failed');
      }
    } catch (error) {
      setStatus('error');
      setMessage(error.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src={logo}
              alt="Smart Ratings"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset Your Password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">
                  Enter New Password
                </CardTitle>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      New Password
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        validatePassword(e.target.value);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                    <div className="mt-2 space-y-2">
                      {passwordCriteria.map(criterion => (
                        <div
                          key={criterion.id}
                          className={`text-sm ${
                            passwordValidation[criterion.id]
                              ? 'text-green-600'
                              : 'text-gray-500'
                          }`}
                        >
                          <span className="mr-2">
                            {passwordValidation[criterion.id] ? '✓' : '○'}
                          </span>
                          {criterion.label}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                      status === 'loading' ? 'opacity-75 cursor-not-allowed' : ''
                    }`}
                    disabled={status === 'loading'}
                  >
                    {status === 'loading' ? 'Resetting...' : 'Reset Password'}
                  </button>
                </form>

                {message && (
                  <div className={`mt-4 text-sm ${
                    status === 'success' ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {message}
                  </div>
                )}

                {status === 'success' && (
                  <div className="mt-4 text-center">
                    <a
                      href="/"
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Return to Login
                    </a>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}