import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';
import { ExternalLink, Info, Mail, Phone } from 'lucide-react';

const LoginInfoSection = () => {
  return (
    <div className="mt-8 space-y-6">
      <Card>
        <CardContent className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <Info className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-medium">About Smart Ratings</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Smart Ratings provides advanced analytics and ratings for horse racing, helping professionals make data-driven decisions.
          </p>
          <a 
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            <ExternalLink className="h-4 w-4 mr-1" />
            Learn More
          </a>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-medium mb-4">Get in Touch</h3>
          <div className="space-y-4">
            <a 
              href="mailto:info@jhjwconsulting.com"
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
            >
              <Mail className="h-5 w-5" />
              sam.dolbear@jhjwconsulting.com
            </a>
            <a 
              href="tel:+441234567890"
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
            >
              <Phone className="h-5 w-5" />
              placeholder
            </a>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginInfoSection;