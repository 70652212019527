
export default function getRole () {
    const tokenString = sessionStorage.getItem('token')

    if (tokenString) {
      const userToken = JSON.parse(tokenString)
      const role = userToken.user.role;
      return role;
    }
    
    return "trial" // Lowercase to match backend
  
}