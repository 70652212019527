// Utils/RaceUtils.js

// Helper function to get course abbreviation
function getCourseAbbreviation(course) {
  // Add null check to prevent error
  if (!course) {
    console.warn('Course is null or undefined in getCourseAbbreviation');
    return 'unknown';
  }
  
  switch (course.toUpperCase()) {
    case "WINDSOR":
      return "wnd";
    case "SEDGEFIELD":
      return "sdg";
    case "NEWCASTLE":
      return "ncs";
    case "SOUTHWELL":
      return "sth";
    case "KEMPTON":
      return "kmp";
    case "CHELMSFORDCITY":
      return "cfc";
    case "WETHERBY":
      return "wth";
    case "HAYDOCK":
      return "hdo";
    case "CHEPSTOW":
      return "chp";
    case "NEWBURY":
      return "nbu";
    case "ASCOT":
      return "asc";
    case "PARISLONGCHAMP":
      return "lon";
    case "LYON LA SOIE":
      return "lls";
    case "MARSEILLE BORELY":
      return "msy";
    case "TOULOUSE":
      return "tou";
    case "FONTAINEBLEAU":
      return "ftn";
    case "AGEN":
      return "age";
    case "AIX LES BAINS":
      return "aix";
    case "ANGERS":
      return "ang";
    case "ARGENTAN":
      return "agt";
    case "BORDEAUX LE BOUSCAT":
      return "bdx";
    case "CAGNES SUR MER":
      return "cag";
    case "CHANTILLY":
      return "cha";
    case "FONTAINEBLEAU":
      return "ftn";
    case "LIGNIERES":
      return "lig";
    case "LYON LA SOIE":
      return "lls";
    case "LYON PARILLY":
      return "lyo";
    case "MACHECOUL":
      return "mcc";
    case "MARSEILLE BORELY":
      return "msy";
    case "MARSEILLE VIVAUX":
      return "mas";
    case "MONT DE MARSAN":
      return "mdm";
    case "PARISLONGCHAMP":
      return "lon";
    case "PAU":
      return "pau";
    case "PORNICHET LA BAULE":
      return "por";
    case "SAINT CLOUD":
      return "sai";
    case "SENONNES":
      return "sen";
    case "TOULOUSE":
      return "tou";

    
    default:
      return course.substring(0, 3).toLowerCase();
  }
}

// Function to generate the horse URL
function generateHorseUrl(dataSource, horseName, horseID, horseSuf = '', raceDate, course, time, country) {
  let url = '';

  // For debugging missing parameters
  if (!horseName || !horseID) {
    console.warn('Missing required parameters for horse URL generation:', {
      horseName: horseName || '[MISSING]',
      horseID: horseID || '[MISSING]',
      country: country || '[MISSING]',
      dataSource: dataSource || '[MISSING]'
    });
  }

  // For FR (French) horses, always link to France-Galop website
  if (country === 'FR') {
    return 'https://www.france-galop.com/en';
  }

  // Ensure horseName and horseID have fallback values for URL generation
  const safeHorseName = horseName || 'unknown-horse';
  const safeHorseID = horseID || '0';

  // For GB and IRE countries, try to create Timeform links first
  if ((country === 'GB' || country === 'IRE') && horseName && horseID) {
    try {
      const stub1 = 'https://www.timeform.com/horse-racing/horse-form/';
      const stub2 = safeHorseName.toLowerCase().replace(/\s+/g, '-');
      const stub3 = safeHorseID.toString().padStart(12, '0');
      url = `${stub1}${stub2}/${stub3}`;
      return url;
    } catch (error) {
      // If there's any error creating the Timeform URL, fall back to ATR
      console.warn('Error creating Timeform URL, falling back to ATR:', error);
    }
  }

  // Original logic as fallback with safety checks
  if (dataSource === 'TF' && horseName && horseID) {
    const stub1 = 'https://www.timeform.com/horse-racing/horse-form/';
    const stub2 = safeHorseName.toLowerCase().replace(/\s+/g, '-');
    const stub3 = safeHorseID.toString().padStart(12, '0');
    url = `${stub1}${stub2}/${stub3}`;
  } else if ((dataSource === 'ATR' || (!dataSource && dataSource !== 'TDS')) && horseName && horseID) {
    const stub = 'https://www.attheraces.com/form/horse/';
    url = `${stub}${safeHorseName.toLowerCase().replace(/\s+/g, '-')}/${horseSuf}/${safeHorseID}`;
  } else if (dataSource === 'TDS') {
    // Fix for repeated error messages - only log once per missing parameter
    // Create a static log tracker to avoid overwhelming the console
    if (!generateHorseUrl.loggedErrors) {
      generateHorseUrl.loggedErrors = new Set();
    }
    
    if (!raceDate || !course || !time || !country) {
      // Create a hash of the current parameters to track what we've already logged
      const errorKey = `TDS-missing-${!!raceDate}-${!!course}-${!!time}-${!!country}-${horseName}`;
      
      // Only log if we haven't logged this exact combination before
      if (!generateHorseUrl.loggedErrors.has(errorKey)) {
        console.error('Missing parameters for TDS dataSource in generateHorseUrl:', {
          horseName,
          missingDate: !raceDate,
          missingCourse: !course,
          missingTime: !time,
          missingCountry: !country
        });
        generateHorseUrl.loggedErrors.add(errorKey);
        
        // Limit the set size to avoid memory leaks
        if (generateHorseUrl.loggedErrors.size > 100) {
          generateHorseUrl.loggedErrors.clear();
        }
      }
      return '';
    }

    // Get time offset for the country
    const timeOffset = COUNTRY_TIME_OFFSETS[country] || 0;

    // Adjust time by applying the time offset
    const timeDigits = time.replace(/\D/g, '');
    let hours = parseInt(timeDigits.substring(0, 2), 10);
    const minutes = parseInt(timeDigits.substring(2), 10);

    // Apply time offset
    hours = hours + timeOffset;
    if (hours >= 24) {
      hours -= 24;
    } else if (hours < 0) {
      hours += 24;
    }

    // Format back to 'HHMM'
    const adjustedTime = hours.toString().padStart(2, '0') + minutes.toString().padStart(2, '0');

    // Format raceDate as 'DD-Month-YYYY'
    const dateParts = raceDate.split('-'); // Expected format: 'YYYY-MM-DD'
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1], 10) - 1; // Months are zero-based
    const day = dateParts[2];

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[monthIndex];

    const formattedDate = `${day}-${monthName}-${year}`;

    // Capitalize course name properly
    const courseName = course.charAt(0).toUpperCase() + course.slice(1).toLowerCase();

    url = `https://www.attheraces.com/racecard/${courseName}/${formattedDate}/${adjustedTime}`;
  }

  return url;
}


// Function to generate the horse silk URL
function generateHorseSilk(dataSource, silkCode, raceDate, course, time, osSaddle, country) {
  let url = '';

  // Add null checks for required parameters
  if (!course) {
    console.warn('Course is null or undefined in generateHorseSilk');
    return '/placeholder-silk.png'; 
  }
  
  if (!time) {
    console.warn('Time is null or undefined in generateHorseSilk');
    return '/placeholder-silk.png'; 
  }

  if (dataSource === 'TF' || dataSource === 'RaceForm') {
    const stub1 = 'https://images.timeform.com/silks/opt/';
    const stub2 = silkCode;
    const stub3 = '.png';
    url = `${stub1}${stub2}${stub3}`;
  } else if (dataSource === 'ATR' || dataSource === 'TDS' || !dataSource) {
    // Add null check for raceDate
    if (!raceDate) {
      console.warn('RaceDate is null or undefined in generateHorseSilk');
      return '/placeholder-silk.png';
    }
    
    const raceDateString = raceDate.replace(/-/g, '');
    const courseAbbreviation = getCourseAbbreviation(course);

    // Get time offset for the country
    const timeOffset = COUNTRY_TIME_OFFSETS[country] || 0;

    // Adjust timeString by applying the time offset
    const timeDigits = time.replace(/\D/g, '');
    let hours = parseInt(timeDigits.substring(0, 2), 10);
    const minutes = parseInt(timeDigits.substring(2), 10);

    // Apply time offset
    hours = hours + timeOffset;
    if (hours >= 24) {
      hours -= 24;
    } else if (hours < 0) {
      hours += 24;
    }

    // Format back to 'HHMM'
    const timeString = hours.toString().padStart(2, '0') + minutes.toString().padStart(2, '0');

    const paddedOsSaddle = osSaddle < 10 ? `0${osSaddle}` : osSaddle;
    url = `https://www.attheraces.com/images/silks/${raceDateString}/${raceDateString}${courseAbbreviation}${timeString}${paddedOsSaddle}.png?v=2`;
  }

  return url;
}

const COUNTRY_TIME_OFFSETS = {
  'GB': 0,
  'IRE': 0,
  'FR': -1,
  'US': 5,
  // Add other countries as needed
};

// Export the functions
export {
  getCourseAbbreviation,
  generateHorseUrl,
  generateHorseSilk,
  COUNTRY_TIME_OFFSETS
};
