import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTheme } from '../../Theme/ThemeProvider';

export default function DropdownMenu({ title, menuItems, activePaths, role }) {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  // Detect if the device is touch-capable
  const isTouchDevice =
    typeof window !== 'undefined' &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0);

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setIsOpen(false);
    }
  };

  const toggleOpen = () => {
    if (isTouchDevice) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Popover
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <Popover.Button
          onClick={toggleOpen}
          className={
            activePaths.includes(window.location.pathname)
              ? `inline-flex items-center border-b-2 ${
                  theme === 'blandford' || theme === 'coolmore' ? 'border-white' : 'border-blue-400'
                } px-1 pt-1 text-base font-medium ${
                  theme === 'blandford' || theme === 'coolmore' ? 'text-white' : 'text-gray-900'
                } focus:outline-none align-middle`
              : `inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium ${
                  theme === 'blandford' ? 'text-white hover:border-white hover:text-white' : 
                  theme === 'coolmore' ? 'text-white hover:border-white hover:text-white' :
                  'text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } focus:outline-none align-middle`
          }
        >
          {title}
        </Popover.Button>
      </div>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          static
          className={`absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            theme === 'coolmore' ? 'border-t-2 border-[#000c40]' : ''
          }`}
        >
          <div className="py-1">
            {menuItems.map(({ label, href, showForRole, onClick }, index) => (
              (!showForRole || (Array.isArray(showForRole) ? showForRole.includes(role) : showForRole === role)) && (  // Show if role matches
                <a
                  key={index}
                  href={href}
                  onClick={onClick}
                  className={`block px-4 py-2 text-sm ${
                    theme === 'coolmore' 
                      ? 'text-gray-700 hover:bg-[#edf6f9] hover:text-[#000c40]' 
                      : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                  }`}
                >
                  {label}
                </a>
              )
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}