import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../../Utils/EnhancedTableHead';
import { getComparator, stableSort } from '../../Utils/SortArray';
import formatRaceDate from '../../Utils/FormatRaceDate';

function ExceptionDataTable({ data, exceptionType }) {
  // Define head cells based on exceptionType
  let headCells = [];
  switch (exceptionType) {
    case 'Unrated Races':
      headCells = [
        { id: '_id', numeric: false, label: 'Smart Race ID' },
        { id: 'raceDate', numeric: false, label: 'Race Date' },
        { id: 'raceTime', numeric: false, label: 'Race Time' },
        { id: 'course', numeric: false, label: 'Course' },
        { id: 'PreRaceRated', numeric: true, label: 'Pre Race Rated', align: 'left' },
        { id: 'SmartRated', numeric: true, label: 'Smart Rated', align: 'left' },
        { id: 'WinSmartTimeExist', numeric: true, label: 'Smart Time Exists', align: 'left' },
        { id: 'ctryCode', numeric: false, label: 'Country Code' },
      ];
      break;
    case 'General Exceptions':
      headCells = [
        // Define head cells for General Exceptions
        { id: 'smartDate', numeric: false, label: 'Date' },
        { id: 'smartMeetID', numeric: false, label: 'Smart Meet ID' },
        { id: 'smartRaceID', numeric: false, label: 'Smart Race ID' },
        { id: 'c5', numeric: false, label: 'Course' },
        { id: 'RaceTitle', numeric: false, label: 'Race Title' },
        { id: 'matchType', numeric: false, label: 'Match Type' },
        { id: 'HorseName', numeric: false, label: 'Horse Name' },
        { id: 'PreOrPost', numeric: false, label: 'Pre/Post' },
        { id: 'ExceptionLevel', numeric: false, label: 'Level' },
        { id: 'ExceptionType', numeric: false, label: 'Type' },
        { id: 'RaceSkeleton', numeric: false, label: 'Race Skeleton' },
        { id: 'SkeletonMatches', numeric: false, label: 'Skeleton Matches' },
      ];
      break;
    case 'General Exception Summaries':
      // Define head cells for General Exceptions Summary
      headCells = [
        // { id: '_id', numeric: false, label: 'ID' },
        { id: 'year', numeric: true, label: 'Year', align: 'left' },
        { id: 'ExceptionType', numeric: false, label: 'Type' },
        { id: 'count', numeric: true, label: 'Count', align: 'left' },
        { id: 'pctVsSpecificException', numeric: true, label: '% vs Exception', align: 'left' },
        { id: 'pctOfSubset', numeric: true, label: '% of Subset', align: 'left' },
        { id: 'pctOfTotal', numeric: true, label: '% of Total', align: 'left' },
        { id: 'Checked', numeric: true, label: 'Checked', align: 'left' },
      ];
      break;
    case 'History Vs Live':
      headCells = [
        // Define head cells for History Vs Live
        // { id: '_id', numeric: false, label: 'ID', align: 'left' },
        { id: 'smartDate', numeric: false, label: 'Smart Date', align: 'left' },
        { id: 'smartRaceID', numeric: false, label: 'Smart Race ID', align: 'left' },
        { id: 'HorseName', numeric: false, label: 'Horse Name', align: 'left' },
        { id: 'ExceptionType', numeric: false, label: 'Exception Type', align: 'left' },
        { id: 'historyValue', numeric: true, label: 'History Value', align: 'left' },
        { id: 'liveValue', numeric: true, label: 'Live Value', align: 'left' },
        { id: 'Difference', numeric: true, label: 'Difference', align: 'left' },
      ];
      break;
    default:
      break;
  }

  // State for sorting
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0]?.id || '');

  // Update sorting based on exceptionType
  React.useEffect(() => {
    switch (exceptionType) {
      case 'Unrated Races':
        setOrder('desc');
        setOrderBy('raceDate');
        break;
      case 'Pre Vs Post':
        setOrder('desc');
        setOrderBy('smartDate'); 
        break;
      case 'General Exception Summaries':
        setOrder('desc');
        setOrderBy('Type'); 
        break;
      default:
        setOrder('asc');
        setOrderBy(headCells[0]?.id || '');
    }
  }, [exceptionType]);

  const [sortedRows, setSortedRows] = React.useState([]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (Array.isArray(data)) {
      setSortedRows(stableSort(data, getComparator(order, orderBy)));
    } else {
      setSortedRows([]); 
    }
  }, [order, orderBy, data]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ }}>
        <Table stickyHeader aria-label="exception table">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow key={row._id} hover>
                {/* Render cells based on exceptionType */}
                {headCells.map((cell) => (
                  <TableCell key={cell.id}>
                    {cell.id === 'raceDate' || cell.id === 'smartDate'
                      ? formatRaceDate(row[cell.id])
                      : row[cell.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ExceptionDataTable;
