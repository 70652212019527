import React from 'react';
import { useTheme } from '../Theme/ThemeProvider';

const Footer = () => {
  const { theme, clientName } = useTheme();
  const isBlandford = theme === 'blandford';
  const isCoolmore = theme === 'coolmore';
  
  return (
    <footer className={`${
      isBlandford ? 'bg-[#2f2355] py-6' : 
      isCoolmore ? 'bg-[#000c40] py-6' : 
      'bg-gray-100 py-4'
    } text-center`}>
      <p className={`${
        isBlandford || isCoolmore ? 'text-white' : 'text-gray-600'
      }`}>
        {clientName} - Powered by <a 
          href="https://www.jhjwconsulting.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className={`${
            isBlandford ? 'text-[#ff7e00] hover:text-white' : 
            isCoolmore ? 'text-[#006d77] hover:text-white' : 
            'text-primary'
          }`}
        >
          JHJW Consulting Ltd.
        </a>
      </p>
      {(isBlandford || isCoolmore) && (
        <div className="mt-2 flex justify-center space-x-4">
          {/* <a href="#" className={`${
            isBlandford ? 'text-white hover:text-[#ff7e00]' :
            isCoolmore ? 'text-white hover:text-[#006d77]' :
            ''
          }`}>Privacy Policy</a>
          <a href="#" className={`${
            isBlandford ? 'text-white hover:text-[#ff7e00]' :
            isCoolmore ? 'text-white hover:text-[#006d77]' :
            ''
          }`}>Terms of Service</a>
          <a href="#" className={`${
            isBlandford ? 'text-white hover:text-[#ff7e00]' :
            isCoolmore ? 'text-white hover:text-[#006d77]' :
            ''
          }`}>Contact</a> */}
        </div>
      )}
    </footer>
  );
};

export default Footer;