import React, { useState, useEffect } from 'react';
import { Trash2 } from 'lucide-react';

const DeleteConfirmation = ({ username, onConfirm, onCancel }) => {
  const [confirmText, setConfirmText] = useState('');
  const [countdown, setCountdown] = useState(5);
  const [understood, setUnderstood] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    setIsValid(confirmText === username && understood);
  }, [confirmText, understood, username]);

  useEffect(() => {
    let timer;
    if (showTimer && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown, showTimer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid && countdown === 0) {
      onConfirm();
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex items-center gap-2 text-red-600 mb-4">
        <Trash2 className="w-6 h-6" />
        <h2 className="text-xl font-semibold">Delete Account</h2>
      </div>

      <div className="space-y-4">
        <p className="text-gray-700">
          This action cannot be undone. This will permanently delete your account
          and remove all data associated with it.
        </p>

        <div className="bg-red-50 border-l-4 border-red-600 p-4">
          <p className="text-red-700 font-medium">
            Please type <span className="font-mono">{username}</span> to confirm.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-red-500"
            placeholder="Type your username"
          />

          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={understood}
              onChange={(e) => {
                setUnderstood(e.target.checked);
                if (e.target.checked && isValid) {
                  setShowTimer(true);
                }
              }}
              className="rounded text-red-600 focus:ring-red-500"
            />
            <span className="text-gray-700">
              I understand the consequences of deleting my account
            </span>
          </label>

          <div className="flex gap-4">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
            >
              Cancel
            </button>
            
            <button
              type="submit"
              disabled={!isValid || (showTimer && countdown > 0)}
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {showTimer && countdown > 0 
                ? `Delete in ${countdown}s...` 
                : 'Delete Account'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteConfirmation;