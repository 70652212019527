import React, { useState, useEffect } from 'react'
import useToken from '../../App/useToken'
import fetchWithAuth from '../../Utils/FetchWithAuth'


function GoingAdjustmentsCourse ({ text }) {
  const [error, setError] = useState(null)
  const [goingAdjustments, setGoingAdjustments] = useState([])
  const { token } = useToken()
  
  useEffect(()=>{
    fetchWithAuth('courseGoing', {course: text.courseName, countryCode: text.countryCode, raceDate: text.date}, token)
    .then((result) => setGoingAdjustments(result))
    .catch((error) => setError(error))
  }, [])


  return (

        <div>
        <div className="mx-auto max-w-2xl text-center py-4 sm:py-6">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Course Name: {text.courseName}</h2>
        </div>

            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                        <tr>
                            <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                                Race Time
                            </th>
                            {goingAdjustments.map((race) => (
                            <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                                {race.raceTime}
                            </th>
                            ))}
                        </tr>
                </thead>
                <tbody>
                        <tr>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">Going Assessment</td>
                            {goingAdjustments.map((race) => (
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                    {`${isNaN(Number(race.adjChosen).toFixed(0)) ? '' : Number(race.adjChosen).toFixed(0)}`}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">Going Assessment Category</td>
                            {goingAdjustments.map((race) => (
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                    {race.timedGoing}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">Official Going</td>
                            {goingAdjustments.map((race) => (
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                    {race.OGOING}
                                </td>
                            ))}
                        </tr>
                </tbody>
            </table>
        </div>
  )
}

export default GoingAdjustmentsCourse
