// Utils/RaceUtils.js

// Helper function to get course abbreviation
function getCourseAbbreviation(course) {
  switch (course.toUpperCase()) {
    case "WINDSOR":
      return "wnd";
    case "SEDGEFIELD":
      return "sdg";
    case "NEWCASTLE":
      return "ncs";
    case "SOUTHWELL":
      return "sth";
    case "KEMPTON":
      return "kmp";
    case "CHELMSFORDCITY":
      return "cfc";
    case "WETHERBY":
      return "wth";
    case "HAYDOCK":
      return "hdo";
    case "CHEPSTOW":
      return "chp";
    case "NEWBURY":
      return "nbu";
    case "ASCOT":
      return "asc";
    case "PARISLONGCHAMP":
      return "lon";
    case "LYON LA SOIE":
      return "lls";
      
    default:
      return course.substring(0, 3).toLowerCase();
  }
}

// Function to generate the horse URL
function generateHorseUrl(dataSource, horseName, horseID, horseSuf = '', raceDate, course, time, country) {
  let url = '';

  if (dataSource === 'TF') {
    // ... existing code ...
  } else if (dataSource === 'ATR' || (!dataSource && dataSource !== 'TDS')) {
    // ... existing code ...
  } else if (dataSource === 'TDS') {
    if (!raceDate || !course || !time || !country) {
      console.error('Missing parameters for TDS dataSource in generateHorseUrl');
      return '';
    }

    // Get time offset for the country
    const timeOffset = COUNTRY_TIME_OFFSETS[country] || 0;

    // Adjust time by applying the time offset
    const timeDigits = time.replace(/\D/g, '');
    let hours = parseInt(timeDigits.substring(0, 2), 10);
    const minutes = parseInt(timeDigits.substring(2), 10);

    // Apply time offset
    hours = hours + timeOffset;
    if (hours >= 24) {
      hours -= 24;
    } else if (hours < 0) {
      hours += 24;
    }

    // Format back to 'HHMM'
    const adjustedTime = hours.toString().padStart(2, '0') + minutes.toString().padStart(2, '0');

    // Format raceDate as 'DD-Month-YYYY'
    const dateParts = raceDate.split('-'); // Expected format: 'YYYY-MM-DD'
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1], 10) - 1; // Months are zero-based
    const day = dateParts[2];

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[monthIndex];

    const formattedDate = `${day}-${monthName}-${year}`;

    // Capitalize course name properly
    const courseName = course.charAt(0).toUpperCase() + course.slice(1).toLowerCase();

    url = `https://www.attheraces.com/racecard/${courseName}/${formattedDate}/${adjustedTime}`;
  }

  return url;
}


// Function to generate the horse silk URL
function generateHorseSilk(dataSource, silkCode, raceDate, course, time, osSaddle, country) {
  let url = '';

  if (dataSource === 'TF' || dataSource === 'RaceForm') {
    const stub1 = 'https://images.timeform.com/silks/opt/';
    const stub2 = silkCode;
    const stub3 = '.png';
    url = `${stub1}${stub2}${stub3}`;
  } else if (dataSource === 'ATR' || dataSource === 'TDS' || !dataSource) {
    const raceDateString = raceDate.replace(/-/g, '');
    const courseAbbreviation = getCourseAbbreviation(course);

    // Get time offset for the country
    const timeOffset = COUNTRY_TIME_OFFSETS[country] || 0;

    // Adjust timeString by applying the time offset
    const timeDigits = time.replace(/\D/g, '');
    let hours = parseInt(timeDigits.substring(0, 2), 10);
    const minutes = parseInt(timeDigits.substring(2), 10);

    // Apply time offset
    hours = hours + timeOffset;
    if (hours >= 24) {
      hours -= 24;
    } else if (hours < 0) {
      hours += 24;
    }

    // Format back to 'HHMM'
    const timeString = hours.toString().padStart(2, '0') + minutes.toString().padStart(2, '0');

    const paddedOsSaddle = osSaddle < 10 ? `0${osSaddle}` : osSaddle;
    url = `https://www.attheraces.com/images/silks/${raceDateString}/${raceDateString}${courseAbbreviation}${timeString}${paddedOsSaddle}.png?v=2`;
  }

  return url;
}

const COUNTRY_TIME_OFFSETS = {
  'GB': 0,
  'IRE': 0,
  'FR': -1,
  'US': 5,
  // Add other countries as needed
};

// Export the functions
export {
  getCourseAbbreviation,
  generateHorseUrl,
  generateHorseSilk,
  COUNTRY_TIME_OFFSETS
};
