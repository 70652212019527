import React from 'react'
import StatusDashboard from './components/status_dashboard'
import Footer from '../Footer/Footer'
import { useTheme } from '../Theme/ThemeProvider'
import ClientBranding from './components/ClientBranding'

const Home = () => {
  const { theme } = useTheme();
  const showDescription = theme === 'Trial' || theme === 'admin';
  
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <ClientBranding />
                {showDescription && (
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    A simple front end for displaying Smart Ratings by jurisdiction. This tab can be used to monitor the status' of
                    all races in the UK and IRE today.
                  </p>
                )}
            </div>
            <StatusDashboard></StatusDashboard>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home