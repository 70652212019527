import React from 'react';

const CoolmoreLogo = ({ className = "h-10 w-auto", color = "#000c40" }) => {
  return (
    <svg 
      className={className}
      x="0px" 
      y="0px" 
      viewBox="0 0 621 168"
    >
      <path className="__c" d="M108.7,135.8c-2.3,0.6-11.3,3.5-28.4,2.6C62.2,137.5,40.3,125.5,33,107c-5.6-15.7-5.9-36.4,4.1-50.2
        C44.3,46.8,50.4,43,57,39.3c10.9-5.3,26.3-6.2,37.4-4c8.8,1.7,21.9,6,21.9,6c0.3,5,0,9.3-1.2,13.4c-1.1-1.5-1.5-3.4-2.6-5.4
        c-6.5-9.3-18.9-10.9-30.4-11.5c-14.9,0.1-29.1,6-37,18.9c-10,17.9-6.7,43.8,4.3,59.9c11.8,16.6,32.4,19.5,51.2,16.4
        c8.7-1.7,15.3-7,18-15c0,0-2,13-3.3,14.4C114.6,133.4,111,135.1,108.7,135.8z" 
        fill={color} 
      />
      <path className="__o __o--1" d="M125.4,51c11-7.5,25.5-10.7,39.6-6.7c16.7,4.5,25,20.4,28.1,34.4c1.5,9.6,0,18.9-4.8,26.4
        c-1.4-1.9-1.7-2.5-2.6-4.8c0-1.6,1.7-7.3,0.3-16.9c-1.8-14.8-9.8-29-25.8-36c-12.4-3.5-27.3-2.2-35.4,8.8
        c-9.5,10.7-9.5,29.4-4.6,42.2c4.1,12.4,16.1,24.8,29.7,25.9c11.2,1.3,20.1-3,27.9-9.9c1.3,1.4,1.8,1.9,1.8,1.9s-0.2,0.2-0.6,0.5
        c-10.2,9.4-23.8,12.6-38.1,10.1c-11.3-2-23-11.7-28.2-23.2c-0.6-1.5-0.7-2-1.6-4.3C105.5,81.6,111,62.2,125.4,51z" 
        fill={color} 
      />
      <path className="__o __o--2" d="M175.4,65c2.1,2.1,3.7,6,3.5,6.9c-2.1,19.3,1.2,34.7,17,46.9c7.4,5.2,15.6,6.7,25.1,5
        c12.6-3.5,23-15.9,24.8-28.7c3-18.6-4-35.6-19.9-46.2c-8.8-4.7-21.9-5.4-30.4,0.3c-3,2-5.2,3.9-7.4,6.3c-0.2,0.3-0.3,0.4-0.8,0.9
        c-1.9-2.6-0.7-1.1-2.5-3.2c1.8-2,5.3-4.4,8.5-6.2c14.2-7.2,34.4-5.6,46.5,5.2c13.3,12.1,16.8,29,13,45.5
        c-4,16.4-18.8,26.5-33.8,28.6c-16.5,1.8-32.2-3.6-41.6-17.9C168.8,96.4,168.7,78,175.4,65z" 
        fill={color} 
      />
      <path className="__l" d="M261.2,113.5l0-65.8c-0.1-1.2-1.5-3.1-2.7-3.2l0,0c5.5,0.6,11.3,0.7,16.6,0.1c-1.7,0.8-5,2.6-5.4,5.9l-0.2,70.9
        c6,1.9,9,1.7,15.7,1.4c5-0.2,10.8,0.6,13.4-4.3c-0.7,2.9-1.8,6.1-3.8,8c-12,0-25.1-1.1-38.5-0.9C261.5,123,261.3,119,261.2,113.5z" 
        fill={color} 
      />
      <path className="__m" d="M384,64.1c-0.7,1.2-8.2,16.5-8.2,16.5l-21.7,44.2l-30-60.6c-0.3-0.4-0.5-1.2-1.6-1l-3.3,14.4l-5,26.1
        c-0.8,6.8-5.5,17.8,1.4,22v0.4c-2.5-0.3-15.9,0.3-15.8-0.1c6.3-2.1,8.5-18,9.7-22.5l5.7-29.8c0,0,3.4-19.3,3.4-19.7
        c0.2-3.7-3.5-7-5.4-8.2c-0.9-0.4-3.3-1.7-3.3-1.7s16.2-0.1,15.6,0.3c-2.6,1.6,0.2,7,0.2,7l20,40.3l9.5,18.8l13-25.6l15.2-32
        c1.6-3.1,1.2-5.2-0.6-8.4l15.1,0c-2,1.3-4.3,2.2-5.3,3.7l-0.4,1.2l-0.2,1.5l3.1,20.3l7.6,48.7c0.9,2.5,3.6,4.9,5.8,5.9l0,0.3
        c0.8,0-16.2,0-16.2,0c1.8-2.1,3.5-4.7,2.9-7.9l-9.2-55.1C386.1,63.1,385.1,62.2,384,64.1z" 
        fill={color} 
      />
      <path className="__o __o--3" d="M415.6,56.3c10-10.7,25.2-14.8,40.7-12.5c13.3,2.4,27,13,31.3,26.5c4.2,14,3.4,30.5-5.7,41.1
        c-11.7,16.2-31.2,19.8-48.7,15.1c-15-4.8-26.6-19.9-27.6-35.5C405,77.8,407.8,66,415.6,56.3z M414.4,72.9
        c-1.9,17.1,0.6,33.7,14.1,44.5c9.5,7.8,25.1,10.3,36.3,4.5c11.8-6.4,17.1-19.4,17.3-32.5c-0.5-15-6.2-30.5-20.6-39.1
        c-9.6-6.1-23.7-5.1-33.1,0.8C420.5,56.5,416,64.1,414.4,72.9z" 
        fill={color} 
      />
      <path className="__re" d="M516.7,45.4c8.7,0.8,16.7,4.5,19.2,12.5c2.2,7.8,1.6,17.1-3.9,22.9c-3.7,3.8-5.8,4.9-9.1,6.9l15.3,22
        c4.4,5.6,11.5,14.4,14.8,14c0.9-0.1,1.1-1.1,1.1-1.1l0-72.8l-0.3-1.7l-0.9-1.5l-1.9-1.9l15.3-0.3l18,0.7c1.6,1.7,2.1,2.6,2.4,6.3
        c-6-4.6-17.1-3.1-24.2-2.1l-0.1,29.8c0.1,3-0.2,3.1,2.4,3.4c8.2-0.4,16.6,1.2,22.3-4.2l0,10.5c-5.8-5.7-21.4-2.9-23.6-2.6
        c-0.7,0.1-1.1,1.6-1.1,1.6l0,33.8c-0.1,1.2,0.9,1.1,1.9,1.1l26.7,0c2.3-0.1,4.7-2.3,5.9-4.7c0.4,2.2-0.3,4.3-1.1,5.9
        c0,0-1.8,2.8-3.4,2.7c-0.6,0-37.2,0.2-37.2,0.2c-6.9-0.1-11.6-1.2-17.6-7.6l-23.4-30.5c-1.9-0.2-6.7-0.9-9.6,0.8
        c0,0,0.2,32.9,0.2,34c-0.1,2.3,3.1,3.5,2.7,3.4c-1.1-0.1-10.7,0-14.3,0c0,0,3.2-2,4-4.1c0.5-1.2,0.3-65.9,0.3-65.9
        c0-3.8,0.2-8.9-4.8-10.7C492.6,46.3,508.9,44.6,516.7,45.4z M504.4,84.3c0,0-0.1,0.4,0.8,1c0.9,0.6,7.3,0.7,10.7,0.1
        c5-1,10.3-4.8,12.3-10.1c0.7-1.8,1-3,1.1-5.9c0-6.7-0.4-13.4-6.3-17.7c-5-3.3-12.5-5.2-18.6-3L504.4,84.3z" 
        fill={color} 
      />
    </svg>
  );
};

export default CoolmoreLogo;