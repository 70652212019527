// src/components/Admin/ManageInvitations.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';
import { getComparator, stableSort } from '../Utils/SortArray';

export default function ManageInvitations() {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [actionResult, setActionResult] = useState({ message: '', type: '' });
  const { token } = useToken();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const fetchInvitations = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await fetchWithAuth(
        'invitations',
        null,
        token,
        'GET'
      );
      
      if (Array.isArray(response)) {
        setInvitations(response);
      } else {
        setError('Invalid response format');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to fetch invitations');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  const handleResendInvitation = async (invitationId) => {
    setLoading(true);
    setActionResult({ message: '', type: '' });
    
    try {
      const response = await fetchWithAuth(
        `invitations/${invitationId}/resend`,
        {}, // Empty object instead of null
        token,
        'POST'
      );
      
      if (response && response.message) {
        setActionResult({ message: response.message, type: 'success' });
        // Delay the refresh to ensure UI feedback is shown to the user
        setTimeout(() => {
          fetchInvitations(); // Refresh the list
        }, 1000);
      } else if (response && response.error) {
        setActionResult({ message: response.error, type: 'error' });
      } else {
        setActionResult({ message: 'Unexpected response from server', type: 'error' });
      }
    } catch (err) {
      setActionResult({ 
        message: err.message || 'Failed to resend invitation', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'accepted':
        return 'bg-green-100 text-green-800';
      case 'expired':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Sort the invitations
  const sortedInvitations = stableSort(invitations, getComparator(order, orderBy));

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Manage Invitations
          </CardTitle>
        </CardHeader>
        <CardContent>
          {actionResult.message && (
            <div className={`mb-4 p-3 rounded-md ${actionResult.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {actionResult.message}
            </div>
          )}

          {error && (
            <div className="text-red-600 text-sm mb-4">{error}</div>
          )}

          {loading && <div className="text-center py-4">Loading...</div>}

          {!loading && invitations.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No invitations found. <a href="/invite" className="text-blue-600 hover:underline">Create new invitations</a>
            </div>
          )}

          {!loading && invitations.length > 0 && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('email')}
                    >
                      Email {orderBy === 'email' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('role')}
                    >
                      Role {orderBy === 'role' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('status')}
                    >
                      Status {orderBy === 'status' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Created {orderBy === 'createdAt' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleRequestSort('expiresAt')}
                    >
                      Expires {orderBy === 'expiresAt' && (order === 'desc' ? '▼' : '▲')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {sortedInvitations.map((invitation) => (
                    <tr key={invitation._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{invitation.email}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500 capitalize">{invitation.role}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(invitation.status)}`}>
                          {invitation.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(invitation.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(invitation.expiresAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {invitation.status === 'pending' && (
                          <button
                            onClick={() => handleResendInvitation(invitation._id)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Resend
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-6 flex justify-between">
            <button
              onClick={fetchInvitations}
              disabled={loading}
              className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors disabled:bg-gray-100 disabled:text-gray-400"
            >
              Refresh
            </button>
            
            <a
              href="/invite"
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
            >
              Create New Invitation
            </a>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}