async function fetchWithAuth(endpoint, params, token) {
    // Construct the base URL
    let url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;

    // Check if params is provided and not null
    if (params) {
        // Generate the query string
        const queryString = new URLSearchParams(params).toString();

        // Determine the correct separator ('?' or '&')
        const separator = url.includes('?') ? '&' : '?';

        // Append the query string to the URL
        url += `${separator}${queryString}`;
    }

    // Make the fetch request
    const response = await fetch(url, {
        headers: {
            "Authorization": `Bearer ${token}`,
            
        },
        
    });

    // Return the JSON response
    return response.json();
}

export default fetchWithAuth;
