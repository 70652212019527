
export default function getRole () {
    const tokenString = sessionStorage.getItem('token')

    // console.log(tokenString)
    if (tokenString) {
      const userToken = JSON.parse(tokenString)
      return userToken.user.role
    } 
    return "atr"
  
}