import React from 'react'
import StatusDashboard from './components/status_dashboard'
import logo from '../../images/SR.svg'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <img
                  className="mx-auto h-16 w-auto"
                  src={logo}
                  alt="Your Company"
                />
                <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">JHJW Consulting Ltd</h2>
                <h4 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Smart Ratings</h4>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                A simple front end for displaying Smart Ratings by jurisdiction. This tab can be used to monitor the status' of
                 all races in the UK and IRE today.
                </p>
            </div>
            <StatusDashboard></StatusDashboard>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
