import React, { useState } from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/SR.svg'
import Footer from '../Footer/Footer';
import LoginInfoSection from './components/LoginInfoSection'
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { AlertCircle } from 'lucide-react';
import { useTheme } from '../Theme/ThemeProvider';

async function loginUser(credentials) {
  console.log('Sending login request with:', credentials);
  
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      login: credentials.username, 
      password: credentials.password
    })
  });
  
  if (!response.ok) {
    throw new Error('Invalid credentials');
  }
  
  const data = await response.json();
  console.log('Login response:', data);
  return data;
}

export default function Login ({ setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      const user = await loginUser({
        username,
        password
      });
      console.log('Logging User In');
      setToken(user);
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid username/email or password');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-12 sm:px-6">
        <div className="w-full max-w-md space-y-8">
          {/* Logo and Title */}
          <div className="text-center">
            <img className="mx-auto h-10 w-auto" src={logo} alt="Smart Ratings" />
            <h2 className="mt-6 text-2xl font-bold text-gray-900">Sign in to your account</h2>
          </div>
  
           {/* Form */}
            <form className="space-y-6" onSubmit={handleSubmit}>
                  {error && (
                    <div className="p-4 rounded-md bg-red-50 text-red-700">
                      <div className="flex items-center">
                        <AlertCircle className="h-5 w-5 mr-2 text-red-400" />
                        <span>{error}</span>
                      </div>
                    </div>
                  )}
                
                  <div>
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                      Username or Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="current-username"
                        required
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
      
                  <div>
                  <div className="flex items-center justify-between">
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="text-sm">
                        <a href="/forgot-details" className="font-semibold text-indigo-600 hover:text-indigo-500">
                          Forgot details?
                        </a>
                      </div>
                    </div>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
      
                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-70"
                    >
                      {loading ? 'Signing in...' : 'Sign in'}
                    </button>
                  </div>
            </form>
  
          {/* Info Section */}
          <div className="space-y-6 bg-white p-6 rounded-lg shadow-sm">
            <div>
              <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
                <InformationCircleIcon className="h-5 w-5 text-blue-500" />
                About Smart Ratings
              </h3>
              <p className="mt-2 text-gray-600">
                Smart Ratings provides advanced analytics and ratings for horse racing, helping professionals make data-driven decisions.
              </p>
              {/* <a href="#" 
                 className="mt-2 inline-flex text-blue-600 hover:text-blue-500">
                Learn More
              </a> */}
            </div>
  
            <div>
              <h3 className="text-lg font-medium text-gray-900">Get in Touch</h3>
              <div className="mt-2 space-y-2">
                <a href="mailto:dev@jhjwconsulting.com" 
                   className="block text-gray-600 hover:text-gray-900">
                  dev@jhjwconsulting.com
                </a>
                <p className="text-gray-600">Contact for platform access and support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}