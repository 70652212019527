import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { AlertCircle, Bug, HelpCircle, Info, X, Upload, Loader2, Calendar, Clock, Monitor } from 'lucide-react';

const BugReportGuidelines = () => (
  <div className="mb-4 p-4 bg-blue-50 rounded-lg">
    <h4 className="font-medium flex items-center gap-2 mb-2">
      <Bug className="h-4 w-4" />
      Helpful Information for Bug Reports
    </h4>
    <ul className="space-y-2 text-sm text-gray-600">
      <li className="flex items-center gap-2">
        <Calendar className="h-4 w-4" />
        When did this occur? (date and time)
      </li>
      <li className="flex items-center gap-2">
        <Monitor className="h-4 w-4" />
        Which page or feature were you using?
      </li>
      <li>What were you trying to do?</li>
      <li>What happened instead?</li>
      <li>Screenshots of the issue are very helpful</li>
    </ul>
  </div>
);

const HelpRequestGuidelines = () => (
  <div className="mb-4 p-4 bg-blue-50 rounded-lg">
    <h4 className="font-medium flex items-center gap-2 mb-2">
      <HelpCircle className="h-4 w-4" />
      Help Request Guidelines
    </h4>
    <ul className="space-y-2 text-sm text-gray-600">
      <li>Which feature do you need help with?</li>
      <li>What are you trying to achieve?</li>
      <li>Have you tried any specific solutions?</li>
      <li>Include examples if relevant</li>
      <li>Account-related issues? Please include your username</li>
    </ul>
  </div>
);

const SupportPortal = () => {
  const [formData, setFormData] = useState({
    type: 'bug',
    title: '',
    description: '',
    email: '',
    files: []
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData(prev => ({
      ...prev,
      files: [...prev.files, ...newFiles]
    }));
  };

  const removeFile = (indexToRemove) => {
    setFormData(prev => ({
      ...prev,
      files: prev.files.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    setStatus({ type: '', message: '' });
    
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('type', formData.type);
      formDataToSend.append('title', formData.title);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('email', formData.email);
      
      formData.files.forEach((file, index) => {
        formDataToSend.append('files', file);
      });
  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}support/submit`, {
        method: 'POST',
        body: formDataToSend
      });
  
      if (!response.ok) throw new Error('Request failed');
      
      setStatus({ type: 'success', message: 'Request submitted successfully' });
      setFormData({
        type: 'bug',
        title: '',
        description: '',
        email: '',
        files: []
      });
    } catch (error) {
      setStatus({ type: 'error', message: error.message });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              {formData.type === 'bug' ? <Bug className="h-6 w-6" /> : <HelpCircle className="h-6 w-6" />}
              Support Portal
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <button
                type="button"
                onClick={() => setFormData(prev => ({ ...prev, type: 'bug' }))}
                className={`p-4 rounded-lg border ${
                  formData.type === 'bug' 
                    ? 'border-blue-500 bg-blue-50' 
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="flex items-center gap-2">
                  <Bug className="h-5 w-5" />
                  <span className="font-medium">Report a Bug</span>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  Found something not working? Let us know.
                </p>
              </button>

              <button
                type="button"
                onClick={() => setFormData(prev => ({ ...prev, type: 'help' }))}
                className={`p-4 rounded-lg border ${
                  formData.type === 'help' 
                    ? 'border-blue-500 bg-blue-50' 
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="flex items-center gap-2">
                  <HelpCircle className="h-5 w-5" />
                  <span className="font-medium">Request Help</span>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  Need assistance with account access or other issues?
                </p>
              </button>
            </div>

            {formData.type === 'bug' ? <BugReportGuidelines /> : <HelpRequestGuidelines />}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder={formData.type === 'bug' ? "Brief description of the issue" : "How can we help?"}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  rows={6}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder={formData.type === 'bug' 
                    ? "Please provide details about what happened..." 
                    : "Please describe what you need help with..."}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Your Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Attachments
                </label>
                <div className="space-y-2">
                  {formData.files.map((file, index) => (
                    <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-md">
                      <span className="text-sm text-gray-600">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-gray-400 hover:text-red-500"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
                <label className="mt-2 cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                  <Upload className="h-4 w-4 mr-2" />
                  Add Files
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </label>
                {formData.type === 'bug' && (
                  <p className="mt-2 text-sm text-gray-500">
                    Screenshots can help us understand and resolve the issue faster
                  </p>
                )}
              </div>

              {status.message && (
                <div className={`p-4 rounded-md ${
                  status.type === 'success' ? 'bg-green-50' : 'bg-red-50'
                }`}>
                  <div className="flex items-center">
                    {status.type === 'success' ? (
                      <Info className="h-5 w-5 text-green-400" />
                    ) : (
                      <AlertCircle className="h-5 w-5 text-red-400" />
                    )}
                    <span className={`ml-2 text-sm ${
                      status.type === 'success' ? 'text-green-700' : 'text-red-700'
                    }`}>
                      {status.message}
                    </span>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={uploading}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {uploading ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Submitting...
                  </>
                ) : (
                  'Submit Request'
                )}
              </button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SupportPortal;