// Return the current date in yyyy-mm-dd format
function getCurrentDate () {
  const newDate = new Date()
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDate)
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDate)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate)
  return `${ye}-${mo}-${da}`
}

export default getCurrentDate
