import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Login from '../Login/Login'
import useToken from './useToken'
import Home from '../Home/Home'
import IRE_F from '../RaceCardTabs/IRE_F'
import GB_F from '../RaceCardTabs/GB_F'
import FR_F from '../RaceCardTabs/FR_F'
import IRE_J from '../RaceCardTabs/IRE_J'
import GB_J from '../RaceCardTabs/GB_J'
import NavBar from '../Navigation/NavigationTailwind'
import GoingAdjustments from '../GoingAdjustments/GoingAdjustments'
import GoingAdjustmentsFR from '../GoingAdjustments/GoingAdjustmentsFR'
import LeaderBoardsFlat from '../LeaderBoardFlat/LeaderBoardsFlat'
import LeaderBoardsFlat_FR from '../leaderboardFlat_FR/LeaderBoardsFlat_FR'
import LeaderBoardsJump from '../LeaderBoardJump/LeaderBoardsJump'
import Search from '../Search/Search'
import ExceptionDashboard from '../Exceptions/ExceptionDashboard';
import SearchFR from '../Search/SearchFR'


function App () {
  const { token, setToken } = useToken()       

  useEffect(() => {
    if (window.location.href === 'https://smart-ratings-fe.herokuapp.com/') {
      window.location.href = 'https://smartratingsblandford.com/';
    }
  }, []);

  if (!token) {
    console.log('No Token Found')
    return <Login setToken={setToken} />
  }

  return (
      <BrowserRouter forceRefresh>
        <div>
          <NavBar />
            <Route path="/" component={Home} exact/>
            <Route path="/IRE_F" component={IRE_F}/>
            <Route path="/GB_F" component={GB_F}/>
            <Route path="/FR_F" component={FR_F}/>
            {/* <Route path="/FR_J" component={FR_J}/> */}
            <Route path="/IRE_J" component={IRE_J}/>
            <Route path="/GB_J" component={GB_J}/>
            <Route path="/GoingAdjustments" component={GoingAdjustments}/>
            <Route path="/GoingAdjustmentsFR" component={GoingAdjustmentsFR}/>
            <Route path="/LeaderBoardFlat" component={LeaderBoardsFlat}/>
            <Route path="/LeaderBoardFlat_FR" component={LeaderBoardsFlat_FR}/>
            <Route path="/Search" component={Search}/>
            <Route path="/SearchFR" component={SearchFR}/>
            <Route path="/LeaderBoardJump" component={LeaderBoardsJump}/>
            <Route path="/ExceptionDashboard" component={ExceptionDashboard}/> 
        </div>
      </BrowserRouter>
  )
}

export default App
