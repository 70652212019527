import React from 'react'
import CountryRacecard from './components/CountryRacecard.js'
import logo from '../../images/SR.svg'
import Footer from '../Footer/Footer'

const GB_J = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
            <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <img
                      className="mx-auto h-16 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                    <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">JHJW Consulting Ltd</h2>
                    <h3 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Ratings for jumps races in Great Britain</h3>
                </div>
                <div>
                    <CountryRacecard text={{ countryCode: 'GB_J' }}></CountryRacecard>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default GB_J
